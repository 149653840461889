import { deleteRequest, patchRequest, postRequest } from "@/helpers";
import getUrl from "@/constants/api/index.js";

const state = {
	questions: [],
};

const mutations = {
	SET_QUESTIONS: function (state, questions) {
		state.questions = questions;
	},
	ADD_QUESTION: function (state, question) {
		state.questions.push(question);
	},
	EDIT_QUESTION: function (state, { index, question }) {
		state.questions.splice(index, 1, question);
	},
	DELETE_QUESTION: function (state, index) {
		state.questions.splice(index, 1);
	},
	RESET_QUESTIONS_STATE: function (state) {
		state.questions = [];
	},
};

const actions = {
	async getQuestions(context, body) {
		const { data, error, message } = await postRequest(
			getUrl("questions"),
			body,
			{
				withCredentials: true,
			}
		);
		if (!error) {
			context.commit("SET_QUESTIONS", data);
			return { error: false };
		}
		return { error: error, message: message };
	},
	addQuestion: async function (context, input) {
		const { data, error, message } = await postRequest(
			`${getUrl("questions")}/question`,
			input,
			{
				withCredentials: true,
			}
		);
		if (error) {
			return message;
		}
		context.commit("ADD_QUESTION", data);
	},
	async editQuestion(context, { id, input }) {
		const { data, error, message } = await patchRequest(
			`${getUrl("questions")}/question/${id}`,
			input,
			{
				withCredentials: true,
			}
		);
		if (!error) {
			context.commit("EDIT_QUESTION", {
				index: context.state.questions.findIndex(
					(item) => item.id === id
				),
				question: data,
			});
			return { error: false };
		}
		return { error: true, message: message };
	},
	async deleteQuestion(context, id) {
		const { error, message } = await deleteRequest(
			`${getUrl("questions")}/question/${id}`,
			{ withCredentials: true }
		);
		if (!error) {
			context.commit(
				"DELETE_QUESTION",
				context.state.questions.findIndex((item) => item.id === id)
			);
			return { error: false };
		}
		return { error: true, message: message };
	},
};

const getters = {
	questions: (state) => state.questions,
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
};
