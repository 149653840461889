import getUrl from "@/constants/api/index.js";
import { getRequest, postRequest } from "@/helpers";

const state = {
	configurations: null,
};

const mutations = {
	SET_CONFIGURATIONS: function (state, configurations) {
		state.configurations = configurations;
	},
	RESET_CONFIGURATION_STATE: function (state) {
		state.configurations = null;
	},
};

const actions = {
	getConfigurations: async function (context) {
		const { data, error } = await getRequest(getUrl("configurations"));
		if (!error) {
			context.commit("SET_CONFIGURATIONS", data);
		}
	},
	saveConfigurations: async function (context, value) {
		const { data, error } = await postRequest(
			getUrl("configurations"),
			{
				value: value,
			},
			{ withCredentials: true }
		);
		if (!error) {
			context.commit("SET_CONFIGURATIONS", data);
		}
	},
};

const getters = {
	configurations: (state) => state.configurations,
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
};
