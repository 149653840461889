import { patchRequest, postRequest } from "../../helpers";
import getUrl from "../../constants/api";

const state = {
	chapters: [],
};

const mutations = {
	SET_CHAPTERS: function (state, chapters) {
		state.chapters = chapters;
	},
	ADD_CHAPTER: function (state, chapter) {
		state.chapters.push(chapter);
	},
	EDIT_CHAPTER: function (state, { index, chapter }) {
		state.chapters.splice(index, 1, chapter);
	},
	DELETE_CHAPTER: function (state, index) {
		state.chapters.splice(index, 1);
	},
	RESET_CHAPTERS_STATE: function (state) {
		state.chapters = [];
	},
};

const actions = {
	async getChapters(context, body) {
		const { data, error } = await postRequest(getUrl("chapters"), body, {
			withCredentials: true,
		});
		if (!error) {
			context.commit("SET_CHAPTERS", data);
		}
	},
	async editChapter(context, { id, input }) {
		const { data, error, message } = await patchRequest(
			`${getUrl("chapters")}/chapter/${id}`,
			input,
			{
				withCredentials: true,
			}
		);
		if (!error) {
			context.commit("EDIT_CHAPTER", {
				index: context.state.chapters.findIndex(
					(item) => item.id === id
				),
				chapter: data,
			});
		}
		return { error, message };
	},
	deleteChapter: function (context, chapter) {
		context.commit(
			"DELETE_CHAPTER",
			context.state.chapters.findIndex((item) => item.id === chapter.id)
		);
	},
};

const getters = {
	chapters: (state) => state.chapters,
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
};
