import {
	deleteRequest,
	getRequest,
	postRequest,
	putRequest,
} from "@/helpers/index.js";
import getUrl from "@/constants/api/index.js";

const state = {
	users: [],
	pageInfo: {
		hasNextPage: true,
		nextPage: 1,
	},
};

const mutations = {
	SET_USERS: function (state, users) {
		state.users = users;
		state.pageInfo = null;
	},
	APPEND_USERS: function (state, { edges, pageInfo }) {
		for (let edge of edges) {
			if (!state.users.find((user) => user.id === edge.id)) {
				state.users.push(edge);
			}
		}
		state.pageInfo = pageInfo;
	},
	ADD_USER: function (state, user) {
		state.users.push(user);
	},
	EDIT_USER: function (state, { index, user }) {
		state.users.splice(index, 1, user);
	},
	DELETE_USER: function (state, index) {
		state.users.splice(index, 1);
	},
	RESET_USERS_STATE: function (state) {
		state.users = [];
	},
};

const actions = {
	async getUsers({ commit, state }, { direction }) {
		let nextPage = state.pageInfo?.nextPage;
		const params = new URLSearchParams();
		if (nextPage) {
			params.append("cursor", nextPage);
		}
		if (direction) {
			params.append("direction", direction);
		}
		const url = `${getUrl("users")}?${params.toString()}`;
		const { error, data } = await getRequest(url, {
			withCredentials: true,
		});
		if (!error) {
			commit("APPEND_USERS", data);
		}
	},
	async filterUsers({commit}, input) {
		const { error, data } = await getRequest(`${getUrl("users")}/search?input=${input}`, {
			withCredentials: true,
		});
		if (!error) {
			commit("SET_USERS", data);
		}
	},
	async addUser(context, input) {
		const { error, data, message } = await postRequest(
			`${getUrl("users")}/user`,
			input,
			{
				withCredentials: true,
			}
		);
		if (!error) {
			context.commit("ADD_USER", data);
			return { error: false };
		}
		return { error: true, message: message };
	},
	async editUser(context, { id, input }) {
		const { error, data, message } = await putRequest(
			`${getUrl("users")}/user/${id}`,
			input,
			{
				withCredentials: true,
			}
		);
		if (!error) {
			context.commit("EDIT_USER", {
				index: context.state.users.findIndex((item) => item.id === id),
				user: data,
			});
			return { error: false };
		}
		return { error: true, message: message };
	},
	async resetUserPassword(context, { id, input }) {
		const { error, data, message } = await putRequest(
			`${getUrl("users")}/user/${id}/reset-password`,
			input,
			{ withCredentials: true }
		);
		if (!error) {
			context.commit("EDIT_USER", {
				index: context.state.users.findIndex((item) => item.id === id),
				user: data,
			});
			return { error: false };
		}
		return { error: true, message: message };
	},
	async deleteUser(context, id) {
		const { error, message } = await deleteRequest(
			`${getUrl("users")}/user/${id}`,
			{ withCredentials: true }
		);
		if (!error) {
			context.commit(
				"DELETE_USER",
				context.state.users.findIndex((item) => item.id === id)
			);
			return { error: false };
		}
		return { error: true, message: message };
	},
	async verifyPassword(context, { id, input }) {
		const { error, message } = await postRequest(
			`${getUrl("users")}/user/${id}/verify-password`,
			input,
			{ withCredentials: true }
		);
		if (!error) {
			return { error: false };
		}
		return { error: true, message: message };
	},
};

const getters = {
	users: (state) => state.users,
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
};
