import Vue from "vue";
import { postRequest } from "@/helpers/index.js";
import getUrl from "@/constants/api/index.js";

const state = {
	// Details
	locations: [],
	selectedLocations: [],
	years: [],
	selectedYears: [],
	themes: [],
	selectedThemes: [],
	chapters: [],
	selectedChapters: [],
	courses: [],
	selectedCourses: [],
	sources: [],
	selectedSources: [],
	// Clinical Case
	clinicalCase: null,
	// Questions
	questions: [],
	questionIndex: 0,
};

const mutations = {
	// Details
	SET_LOCATIONS(state, locations) {
		state.locations = locations;
	},
	SET_SELECTED_LOCATIONS(state, payload) {
		state.selectedLocations = payload;
	},
	SET_YEARS(state, years) {
		state.years = years;
	},
	SET_SELECTED_YEARS(state, payload) {
		state.selectedYears = payload;
	},
	SET_THEMES(state, themes) {
		state.themes = themes;
	},
	SET_SELECTED_THEMES(state, payload) {
		state.selectedThemes = payload;
	},
	SET_CHAPTERS(state, chapters) {
		state.chapters = chapters;
	},
	SET_SELECTED_CHAPTERS(state, payload) {
		state.selectedChapters = payload;
	},
	SET_COURSES(state, courses) {
		state.courses = courses;
	},
	SET_SELECTED_COURSES(state, payload) {
		state.selectedCourses = payload;
	},
	SET_SOURCES(state, sources) {
		state.sources = sources;
	},
	SET_SELECTED_SOURCES(state, payload) {
		state.selectedSources = payload;
	},
	// Clinical case
	SET_CLINICAL_CASE_SCENARIO(state, payload) {
		if (!state.clinicalCase) {
			state.clinicalCase = {};
		}
		Vue.set(state.clinicalCase, "scenario", payload);
	},
	SET_CLINICAL_CASE_IMAGES(state, payload) {
		if (!state.clinicalCase) {
			state.clinicalCase = {};
		}
		Vue.set(state.clinicalCase, "images", payload);
	},
	// Questions
	SET_NUMBER(state, payload) {
		if (!state.questions[state.questionIndex]) {
			state.questions.push({});
		}
		Vue.set(state.questions[state.questionIndex], "number", payload);
	},
	SET_VALUE(state, value) {
		if (!state.questions[state.questionIndex]) {
			state.questions.push({});
		}
		Vue.set(state.questions[state.questionIndex], "value", value);
	},
	SET_GROUPS(state, groups) {
		if (!state.questions[state.questionIndex]) {
			state.questions.push({});
		}
		Vue.set(state.questions[state.questionIndex], "groups", groups);
	},
	SET_EXPLANATION(state, explanation) {
		if (!state.questions[state.questionIndex]) {
			state.questions.push({});
		}
		Vue.set(
			state.questions[state.questionIndex],
			"explanation",
			explanation
		);
	},
	SET_PROPOSITIONS(state, propositions) {
		if (!state.questions[state.questionIndex]) {
			state.questions.push({});
		}
		Vue.set(
			state.questions[state.questionIndex],
			"propositions",
			propositions
		);
	},
	SET_ANSWERS(state, answers) {
		if (!state.questions[state.questionIndex]) {
			state.questions.push({});
		}
		Vue.set(state.questions[state.questionIndex], "answers", answers);
	},
	SET_IMAGES(state, payload) {
		if (!state.questions[state.questionIndex]) {
			state.questions.push({});
		}
		Vue.set(state.questions[state.questionIndex], "images", payload);
	},
	SET_QUESTIONS(state, questions) {
		state.questions = questions;
	},
	SET_CLINICAL_CASE(state, clinicalCase) {
		state.clinicalCase = clinicalCase;
	},
	SET_QUESTION_INDEX(state, payload) {
		state.questionIndex = payload;
	},
	DECREMENT_QUESTION_INDEX(state) {
		state.questionIndex--;
	},
	DELETE_QUESTION(state, payload) {
		state.questions.splice(payload, 1);
	},
	INCREMENT_QUESTION_INDEX(state) {
		state.questionIndex++;
	},
	ADD_QUESTION(state) {
		state.questions.push({});
		state.questionIndex++;
	},
};

const actions = {
	// Contribution
	async uploadImages(context, { images, folder }) {
		if (images?.length > 0) {
			let copy = [...images];

			const addedImages = images
				.filter((item) => item.action === "add")
				.map((item) => item.file);

			if (addedImages?.length > 0) {
				const formData = new FormData();
				for (let file of addedImages) {
					formData.append("image", file);
				}
				const { data, error } = await postRequest(
					`${getUrl("images")}/${folder}`,
					formData,
					{
						withCredentials: true,
						headers: {
							"Content-Type": "multipart/form-data",
						},
					}
				);

				if (!error) {
					copy = copy.filter((item) => item.action !== "add");
					for (let item of data) {
						copy.push({
							...item,
							action: "add",
							uploaded: true,
						});
					}
				}
			}

			return copy;
		}
		return [];
	},
	async saveContribution(context) {
		const details = {
			location: context.state.selectedLocations[0].id,
			year: context.state.selectedYears[0].id,
			theme: context.state.selectedThemes[0].id,
			chapter: context.state.selectedChapters[0].id,
			courses: context.state.selectedCourses.map((course) => course.id),
			sources: context.state.selectedSources.map((source) => source.id),
		};

		// 1. Save images
		if (context.state.clinicalCase && context.state.clinicalCase.images) {
			context.commit(
				"SET_CLINICAL_CASE_IMAGES",
				await context.dispatch("uploadImages", {
					images: context.state.clinicalCase.images,
					folder: "clinical-cases",
				})
			);
		}
		for (let question of context.state.questions) {
			if (question.images) {
				context.commit(
					"SET_IMAGES",
					await context.dispatch("uploadImages", {
						images: question.images,
						folder: "questions",
					})
				);
			}
		}

		// 2. Save contribution
		const { data, error, message } = await postRequest(
			getUrl("contribution"),
			{
				details: details,
				clinicalCase: context.getters.isClinicalCase
					? context.state.clinicalCase
					: null,
				questions: context.state.questions,
			},
			{
				withCredentials: true,
			}
		);
		return { data, error, message };
	},
	// Details
	async getLocations(context) {
		const { data, error, message } = await postRequest(
			getUrl("locations"),
			null,
			{
				withCredentials: true,
			}
		);
		if (!error) {
			context.commit("SET_LOCATIONS", data);
			return { error };
		} else {
			return { error, message };
		}
	},
	async getYears(context) {
		const { data, error, message } = await postRequest(
			getUrl("years"),
			null,
			{
				withCredentials: true,
			}
		);
		if (!error) {
			context.commit("SET_YEARS", data);
			return { error };
		} else {
			return { error, message };
		}
	},
	async getThemes(context) {
		if (context.state.selectedYears?.length === 0) {
			return;
		}

		const { data, error, message } = await postRequest(
			getUrl("themes"),
			{
				yearId: context.state.selectedYears[0]?.id,
				parentId: null,
				all: true,
				includePending: true,
			},
			{
				withCredentials: true,
			}
		);
		if (!error) {
			context.commit("SET_THEMES", data);
			return { error };
		} else {
			return { error, message };
		}
	},
	async getChapters(context) {
		if (context.state.selectedThemes?.length === 0) {
			return;
		}

		const { data, error, message } = await postRequest(
			getUrl("chapters"),
			{
				all: true,
				themeId: context.state.selectedThemes[0]?.id,
			},
			{
				withCredentials: true,
			}
		);
		if (!error) {
			context.commit("SET_CHAPTERS", data);
			return { error };
		} else {
			return { error, message };
		}
	},
	async getCourses(context) {
		if (
			context.state.selectedThemes?.length === 0 ||
			context.state.selectedChapters?.length === 0
		) {
			return;
		}

		const chapters = context.state.isExam
			? context.state.selectedChapters?.map((c) => c.id)
			: [context.state.selectedChapters[0]?.id];

		const { data, error, message } = await postRequest(
			getUrl("courses"),
			{
				all: true,
				theme: context.state.selectedThemes[0]?.id,
				chapters: chapters,
			},
			{
				withCredentials: true,
			}
		);
		if (!error) {
			context.commit("SET_COURSES", data);
			return { error };
		} else {
			return { error, message };
		}
	},
	async getSources(context) {
		if (
			context.state.selectedLocations?.length === 0 ||
			context.state.selectedThemes?.length === 0
		) {
			return;
		}

		const { data, error, message } = await postRequest(
			`${getUrl("sources")}`,
			{
				location: context.state.selectedLocations[0]?.id,
				theme: context.state.selectedThemes[0]?.id,
			},
			{
				withCredentials: true,
			}
		);
		if (!error) {
			context.commit("SET_SOURCES", data);
			return { error };
		} else {
			return { error, message };
		}
	},
};

const getters = {
	isClinicalCase: (state) => state.selectedChapters[0]?.title === "clinique",
	locations: (state) => state.locations,
	selectedLocations: (state) => state.selectedLocations,
	years: (state) => state.years,
	selectedYears: (state) => state.selectedYears,
	themes: (state) => state.themes,
	selectedThemes: (state) => state.selectedThemes,
	chapters: (state) => state.chapters,
	selectedChapters: (state) => state.selectedChapters,
	courses: (state) => state.courses,
	selectedCourses: (state) => state.selectedCourses,
	sources: (state) => state.sources,
	selectedSources: (state) => state.selectedSources,
	clinicalCase: (state) => state.clinicalCase,
	questions: (state) => state.questions,
	questionIndex: (state) => state.questionIndex,
	currentQuestion: (state) => state.questions[state.questionIndex],
	details: (state) => {
		return {
			location: state.selectedLocations[0],
			year: state.selectedYears[0],
			theme: state.selectedThemes[0],
			chapter: state.selectedChapters[0],
			courses:
				state.selectedCourses?.length > 0 ? state.selectedCourses : [],
			sources:
				state.selectedSources?.length > 0 ? state.selectedSources : [],
		};
	},
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
};
