import Vue from "vue";
import i18n from "@/i18n";
import { mapActions, mapGetters, mapState } from "vuex";

Vue.mixin({
	data() {
		return {
			isFullscreen: false,
			validationRules: {
				required: (value) =>
					!!value || this.capitalizeFirst(i18n.t("requiredField")),
				percentage: (value) =>
					(value > 0 && value <= 100) ||
					this.capitalizeFirst(i18n.t("percentageField")),
				greaterThanZero: (value) => {
					return (
						value > 0 ||
						this.capitalizeFirst(i18n.t("greaterThanZeroField"))
					);
				},
				passwordMatch: (password, confirm) =>
					password === confirm ||
					this.capitalizeFirst(i18n.t("passwordsMustMatch")),
				passwordLength: (password) => {
					if (password) {
						return (
							(password.length <= 60 && password.length >= 8) ||
							this.capitalizeFirst(i18n.t("minimumCharacters"))
						);
					}
					return "";
				},
				username: (value) =>
					!value ||
					/^[a-zA-Z0-9_]{5,}[a-zA-Z]+[0-9]*$/.test(value) ||
					this.capitalizeFirst(i18n.t("invalidUsername")),
				email: (value) =>
					!value ||
					/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value) ||
					this.capitalizeFirst(i18n.t("invalidEmail")),
				uniqueUsername: (value) =>
					!this.users.find((item) => item.username === value) ||
					this.capitalizeFirst(i18n.t("usernameTaken")),
			},
			paymentMethods: [
				{
					name: i18n.t("pointOfSale"),
					value: "pointOfSale",
				},
				{
					name: i18n.t("baridimob"),
					value: "baridimob",
				},
				{
					name: i18n.t("ccp"),
					value: "ccp",
				},
			],
			developer: {
				twitter: "https://x.com/opso_co",
				instagram: "https://instagram.com/opso_co",
			},
			productContact: {
				email: "contact@opso.co",
			},
		};
	},
	computed: {
		getOS() {
			const ua = navigator.userAgent;
			if (/android/i.test(ua)) {
				return "Android";
			} else if (/iPhone/.test(ua)) {
				return "iOS";
			}
			return "Other";
		},
		genderOptions() {
			return [
				{
					value: "female",
					text: i18n.t("female"),
				},
				{
					value: "male",
					text: i18n.t("male"),
				},
			];
		},
		isValid() {
			if (!this.connectedUser) {
				return false;
			}
			return this.connectedUser.status === "valid";
		},
		hideNotesInExam() {
			return this.preferences?.find(p => p.label === "hide-notes-in-exam")?.value === "true";
		},
		...mapState(["preferences"]),
		...mapGetters("auth", ["connectedUser", "isAuthenticated", "isPremium", "isAdmin"]),
	},
	methods: {
		toggleFullScreen() {
			const doc = window.document;
			const docEl = doc.documentElement;

			const requestFullScreen =
				docEl.requestFullscreen ||
				docEl.mozRequestFullScreen ||
				docEl.webkitRequestFullScreen ||
				docEl.msRequestFullscreen;
			const cancelFullScreen =
				doc.exitFullscreen ||
				doc.mozCancelFullScreen ||
				doc.webkitExitFullscreen ||
				doc.msExitFullscreen;

			if (
				!doc.fullscreenElement &&
				!doc.mozFullScreenElement &&
				!doc.webkitFullscreenElement &&
				!doc.msFullscreenElement
			) {
				requestFullScreen.call(docEl);
				this.isFullscreen = true;
			} else {
				cancelFullScreen.call(doc);
				this.isFullscreen = false;
			}
		},
		canDo: function(data) {
			if (this.connectedUser) {
				if (
					["pending", "disabled"].includes(this.connectedUser.status)
				) {
					return false;
				}
				const [module, action] = data.split(".");
				const permission = this.connectedUser.role.permissions.find(
					(item) => item.module === module && item.action === action,
				);
				if (permission) {
					return permission.value;
				}
			}
			return false;
		},
		parseDate(date) {
			if (!date) {
				return null;
			}

			const [year, month, day] = date.split("-");
			return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
		},
		formatDate: function(date) {
			if (date) {
				return new Intl.DateTimeFormat("fr-FR").format(new Date(date));
			}
		},
		formatDateWithTime: function(date) {
			if (date) {
				return new Intl.DateTimeFormat("fr-FR", {
					year: "numeric",
					month: "short",
					day: "numeric",
					hour: "numeric",
					minute: "numeric",
				})
					.format(new Date(date))
					.toString()
					.toUpperCase();
			}
			return null;
		},
		dayMonthYearFormat(date) {
			if (date) {
				return this.capitalizeFirst(
					new Intl.DateTimeFormat("fr-FR", {
						year: "numeric",
						month: "long",
						day: "2-digit",
					}).format(new Date(date)),
				);
			}
			return null;
		},
		formatDateForDateField: function(date) {
			if (date) {
				return new Date(date).toISOString().split("T")[0];
			}
			return null;
		},
		formatCurrency: function(value) {
			return new Intl.NumberFormat("fr-FR", {
				style: "currency",
				currency: "DZD",
			})
				.format(value)
				.split("DZD")[0]
				.trim()
				.replace("\u202f", " ");
		},
		capitalizeFirst: function(value) {
			if (typeof value !== "string") return "";
			return value.charAt(0).toUpperCase() + value.slice(1);
		},
		getThemeTree(themes, theme) {
			if (!theme) {
				return;
			}
			let parents = "";
			if (theme.parentId) {
				let parent = themes.find((item) => item.id === theme.parentId);
				while (parent) {
					if (parents === "") {
						parents = `${this.capitalizeFirst(parent.name)}`;
					} else {
						parents = `${this.capitalizeFirst(
							parent.name,
						)} / ${parents}`;
					}

					if (parent.parentId) {
						parent = themes.find(
							(item) => item.id === parent.parentId,
						);
					} else {
						parent = null;
					}
				}
			}
			if (parents !== "") {
				return `${parents} / ${this.capitalizeFirst(theme.name)}`;
			} else {
				return `${this.capitalizeFirst(theme.name)}`;
			}
		},
		getChildrenIds(data, id) {
			let ids = [];
			for (let i = 0; i < data.length; i++) {
				let item = data[i];
				if (item.parentId === id || item.id === id) {
					if (ids.indexOf(item.id) < 0) {
						ids.push(item.id);
						let newIds = [];
						for (let x = 0; x < data.length; x++) {
							if (x !== i) newIds.push(data[x]);
						}
						let children = this.getChildrenIds(newIds, item.id);
						if (children.length > 0) {
							for (let j = 0; j < children.length; j++) {
								if (!(ids.indexOf(children[j]) >= 0)) {
									ids.push(children[j]);
								}
							}
						}
					}
				}
			}
			return ids;
		},
		calculateQuestions(data) {
			let counter = 0;
			for (let item of data) {
				if (item.entity === "question") {
					counter++;
				} else if (item.entity === "clinicalCase") {
					counter += item.questions.length;
				}
			}
			return counter;
		},
		...mapActions("auth", ["authenticated"]),
	},
});
