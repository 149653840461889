import { deleteRequest, patchRequest, postRequest } from "@/helpers/index.js";
import getUrl from "@/constants/api/index.js";
import { getRequest } from "@/helpers";

const state = {
	years: [],
};

const mutations = {
	SET_YEARS: function (state, years) {
		state.years = years;
	},
	ADD_YEAR: function (state, year) {
		state.years.push(year);
	},
	EDIT_YEAR: function (state, { index, year }) {
		state.years.splice(index, 1, year);
	},
	DELETE_YEAR: function (state, index) {
		state.years.splice(index, 1);
	},
	RESET_YEARS_STATE: function (state) {
		state.years = [];
	},
};

const actions = {
	async getYears(context) {
		const { data, error } = await getRequest(`${getUrl("years")}/public`, {
			withCredentials: true,
		});
		if (!error) {
			context.commit("SET_YEARS", data);
		}
	},
	async addYear(context, input) {
		const { data, error, message } = await postRequest(
			`${getUrl("years")}/year`,
			input,
			{
				withCredentials: true,
			}
		);
		if (!error) {
			context.commit("ADD_YEAR", data);
			return { error: false };
		}
		return { error: true, message: message };
	},
	async editYear(context, { id, input }) {
		const { data, error, message } = await patchRequest(
			`${getUrl("years")}/year/${id}`,
			input,
			{
				withCredentials: true,
			}
		);
		if (!error) {
			context.commit("EDIT_YEAR", {
				index: context.state.years.findIndex((item) => item.id === id),
				year: data,
			});
			return { error: false };
		}
		return { error: true, message: message };
	},
	async deleteYear(context, id) {
		const { error, message } = await deleteRequest(
			`${getUrl("years")}/year/${id}`,
			{ withCredentials: true }
		);
		if (!error) {
			context.commit(
				"DELETE_YEAR",
				context.state.years.findIndex((item) => item.id === id)
			);
			return { error: false };
		}
		return { error: true, message: message };
	},
};

const getters = {
	years: (state) => state.years,
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
};
