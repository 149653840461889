import {
	deleteRequest,
	getRequest,
	patchRequest,
	postRequest,
} from "@/helpers/index.js";
import getUrl from "@/constants/api/index.js";

const state = {
	pinnedPlaylists: [],
	playlists: [],
	pageInfo: {
		hasNextPage: true,
		nextPage: 1,
	},
};

const mutations = {
	SET_PLAYLISTS: function (state, playlists) {
		state.playlists = playlists;
		state.pageInfo = null;
	},
	SET_PINNED_PLAYLISTS: function (state, playlists) {
		state.pinnedPlaylists = playlists;
	},
	APPEND_PLAYLISTS: function (state, { edges, pageInfo }) {
		for (let edge of edges) {
			if (!state.playlists.find((playlist) => playlist.id === edge.id)) {
				state.playlists.push(edge);
			}
		}
		state.pageInfo = pageInfo;
	},
	ADD_PLAYLIST: function (state, playlist) {
		state.playlists.unshift(playlist);
	},
	ADD_PINNED_PLAYLIST: function (state, playlist) {
		state.pinnedPlaylists.unshift(playlist);
	},
	EDIT_PLAYLIST: function (state, { index, playlist }) {
		state.playlists.splice(index, 1, playlist);
	},
	EDIT_PINNED_PLAYLIST: function (state, { index, playlist }) {
		state.pinnedPlaylists.splice(index, 1, playlist);
	},
	DELETE_PLAYLIST: function (state, index) {
		state.playlists.splice(index, 1);
	},
	DELETE_PINNED_PLAYLIST: function (state, index) {
		state.pinnedPlaylists.splice(index, 1);
	},
	RESET_PLAYLISTS_STATE: function (state) {
		state.playlists = [];
	},
};

const actions = {
	async getPlaylists({ commit }, { direction }) {
		let nextPage = state.pageInfo?.nextPage;
		let url = getUrl("playlists");
		if (nextPage && direction) {
			url += `?cursor=${nextPage}&direction=${direction}`;
		} else {
			if (nextPage) {
				url += `?cursor=${nextPage}`;
			}
			if (direction) {
				url += `?direction=${direction}`;
			}
		}

		const { data, error } = await getRequest(url, {
			withCredentials: true,
		});
		if (!error) {
			commit("APPEND_PLAYLISTS", data);
		}
	},
	async getPinnedPlaylists({ state, commit }) {
		if (state.pinnedPlaylists.length > 0) return;
		let url = `${getUrl("playlists")}/pinned`;
		const { data, error } = await getRequest(url, {
			withCredentials: true,
		});
		if (!error) {
			commit("SET_PINNED_PLAYLISTS", data);
		}
	},
	async filterPlaylists({ commit }, input) {
		let url = `${getUrl("playlists")}/search?input=${input}`;
		const { data, error } = await getRequest(url, {
			withCredentials: true,
		});
		if (!error) {
			commit("SET_PLAYLISTS", data);
		}
	},
	async addPlaylist(context, input) {
		const { data, error, message } = await postRequest(
			`${getUrl("playlists")}/playlist`,
			input,
			{
				withCredentials: true,
			}
		);
		if (!error) {
			context.commit("ADD_PLAYLIST", data);
			return { error: false };
		}
		return { error: true, message: message };
	},
	async editPlaylist(context, { id, input }) {
		const { data, error, message } = await patchRequest(
			`${getUrl("playlists")}/playlist/${id}`,
			input,
			{
				withCredentials: true,
			}
		);
		if (!error) {
			context.commit("EDIT_PLAYLIST", {
				index: context.state.playlists.findIndex(
					(item) => item.id === id
				),
				playlist: data,
			});
			return { error: false };
		}
		return { error: true, message: message };
	},
	async deletePlaylist(context, id) {
		const { error, message } = await deleteRequest(
			`${getUrl("playlists")}/playlist/${id}`,
			{ withCredentials: true }
		);
		if (!error) {
			context.commit(
				"DELETE_PLAYLIST",
				context.state.playlists.findIndex((item) => item.id === id)
			);
			return { error: false };
		}
		return { error: true, message: message };
	},
	async addQuestionToPlaylist({ state, commit }, { playlistId, questionId }) {
		const path = `${getUrl(
			"playlists"
		)}/playlist/${playlistId}/question/${questionId}`;
		const { error, message } = await patchRequest(path, null, {
			withCredentials: true,
		});
		if (!error) {
			const index = state.playlists.findIndex(
				(item) => item.id === playlistId
			);
			const updatedPlaylist = {
				...state.playlists[index],
				questions: [
					...state.playlists[index].questions,
					{
						id: questionId,
					},
				],
				questionsCount: state.playlists[index].questionsCount + 1,
			};
			commit("EDIT_PLAYLIST", {
				index: index,
				playlist: updatedPlaylist,
			});
			commit("selection/INC_DEC_ATTACHED_TO", 1, { root: true });
			return { error: false };
		}
		return { error: true, message: message };
	},
	async addQuestionToPinnedPlaylist(
		{ commit, state },
		{ playlistId, questionId }
	) {
		const path = `${getUrl(
			"playlists"
		)}/playlist/${playlistId}/question/${questionId}`;
		const { error, message } = await patchRequest(path, null, {
			withCredentials: true,
		});
		if (!error) {
			const index = state.pinnedPlaylists.findIndex(
				(item) => item.id === playlistId
			);
			const updatedPlaylist = {
				...state.pinnedPlaylists[index],
				questions: [
					...state.pinnedPlaylists[index].questions,
					{
						id: questionId,
					},
				],
				questionsCount: state.pinnedPlaylists[index].questionsCount + 1,
			};
			commit("EDIT_PINNED_PLAYLIST", {
				index: index,
				playlist: updatedPlaylist,
			});
			commit("selection/INC_DEC_ATTACHED_TO", 1, { root: true });
			return { error: false };
		}
		return { error: true, message: message };
	},
	async removeQuestionFromPlaylist(
		{ state, commit },
		{ playlistId, questionId }
	) {
		const path = `${getUrl(
			"playlists"
		)}/playlist/${playlistId}/question/${questionId}`;
		const { error, message } = await deleteRequest(path, {
			withCredentials: true,
		});
		if (!error) {
			const index = state.playlists.findIndex(
				(item) => item.id === playlistId
			);
			const updatedPlaylist = {
				...state.playlists[index],
				questions: state.playlists[index].questions.filter(
					(item) => item.id !== questionId
				),
				questionsCount: state.playlists[index].questionsCount - 1,
			};
			commit("EDIT_PLAYLIST", {
				index: index,
				playlist: updatedPlaylist,
			});
			commit("selection/INC_DEC_ATTACHED_TO", -1, { root: true });
			return { error: false };
		}
		return { error: true, message: message };
	},
	async removeQuestionFromPinnedPlaylist(
		{ commit, state },
		{ playlistId, questionId }
	) {
		const path = `${getUrl(
			"playlists"
		)}/playlist/${playlistId}/question/${questionId}`;
		const { error, message } = await deleteRequest(path, {
			withCredentials: true,
		});
		if (!error) {
			const index = state.pinnedPlaylists.findIndex(
				(item) => item.id === playlistId
			);
			const updatedPlaylist = {
				...state.pinnedPlaylists[index],
				questions: state.pinnedPlaylists[index].questions.filter(
					(item) => item.id !== questionId
				),
				questionsCount: state.pinnedPlaylists[index].questionsCount - 1,
			};
			commit("EDIT_PINNED_PLAYLIST", {
				index: index,
				playlist: updatedPlaylist,
			});
			commit("selection/INC_DEC_ATTACHED_TO", -1, { root: true });
			return { error: false };
		}
		return { error: true, message: message };
	},
	async addClinicalCaseToPlaylist(
		{ state, commit },
		{ playlistId, clinicalCaseId }
	) {
		const path = `${getUrl(
			"playlists"
		)}/playlist/${playlistId}/clinical-case/${clinicalCaseId}`;
		const { error, message } = await patchRequest(path, null, {
			withCredentials: true,
		});
		if (!error) {
			const index = state.playlists.findIndex(
				(item) => item.id === playlistId
			);
			const updatedPlaylist = {
				...state.playlists[index],
				clinicalCases: [
					...state.playlists[index].clinicalCases,
					{
						id: clinicalCaseId,
					},
				],
				clinicalCasesCount:
					state.playlists[index].clinicalCasesCount + 1,
			};
			commit("EDIT_PLAYLIST", {
				index: index,
				playlist: updatedPlaylist,
			});
			commit("selection/INC_DEC_ATTACHED_TO", 1, { root: true });
			return { error: false };
		}
		return { error: true, message: message };
	},
	async addClinicalCaseToPinnedPlaylist(
		{ state, commit },
		{ playlistId, clinicalCaseId }
	) {
		const path = `${getUrl(
			"playlists"
		)}/playlist/${playlistId}/clinical-case/${clinicalCaseId}`;
		const { error, message } = await patchRequest(path, null, {
			withCredentials: true,
		});
		if (!error) {
			const index = state.pinnedPlaylists.findIndex(
				(item) => item.id === playlistId
			);
			const updatedPlaylist = {
				...state.pinnedPlaylists[index],
				clinicalCases: [
					...state.pinnedPlaylists[index].clinicalCases,
					{
						id: clinicalCaseId,
					},
				],
				clinicalCasesCount:
					state.pinnedPlaylists[index].clinicalCasesCount + 1,
			};
			commit("EDIT_PINNED_PLAYLIST", {
				index: index,
				playlist: updatedPlaylist,
			});
			commit("selection/INC_DEC_ATTACHED_TO", 1, { root: true });
			return { error: false };
		}
		return { error: true, message: message };
	},
	async removeClinicalCaseFromPlaylist(
		{ state, commit },
		{ playlistId, clinicalCaseId }
	) {
		const path = `${getUrl(
			"playlists"
		)}/playlist/${playlistId}/clinical-case/${clinicalCaseId}`;
		const { error, message } = await deleteRequest(path, {
			withCredentials: true,
		});
		if (!error) {
			const index = state.playlists.findIndex(
				(item) => item.id === playlistId
			);
			const updatedPlaylist = {
				...state.playlists[index],
				clinicalCases: state.playlists[index].clinicalCases.filter(
					(item) => item.id !== clinicalCaseId
				),
				clinicalCasesCount:
					state.playlists[index].clinicalCasesCount - 1,
			};
			commit("EDIT_PLAYLIST", {
				index: index,
				playlist: updatedPlaylist,
			});
			commit("selection/INC_DEC_ATTACHED_TO", -1, { root: true });
			return { error: false };
		}
		return { error: true, message: message };
	},
	async removeClinicalCaseFromPinnedPlaylist(
		{ state, commit },
		{ playlistId, clinicalCaseId }
	) {
		const path = `${getUrl(
			"playlists"
		)}/playlist/${playlistId}/clinical-case/${clinicalCaseId}`;
		const { error, message } = await deleteRequest(path, {
			withCredentials: true,
		});
		if (!error) {
			const index = state.pinnedPlaylists.findIndex(
				(item) => item.id === playlistId
			);
			const updatedPlaylist = {
				...state.pinnedPlaylists[index],
				clinicalCases: state.pinnedPlaylists[
					index
				].clinicalCases.filter((item) => item.id !== clinicalCaseId),
				clinicalCasesCount:
					state.pinnedPlaylists[index].clinicalCasesCount - 1,
			};
			commit("EDIT_PINNED_PLAYLIST", {
				index: index,
				playlist: updatedPlaylist,
			});
			commit("selection/INC_DEC_ATTACHED_TO", -1, { root: true });
			return { error: false };
		}
		return { error: true, message: message };
	},
	async pinUnpinPlaylist({ state, commit }, playlist) {
		const path = `${getUrl("playlists")}/playlist/${playlist.id}/pin`;
		const { error, message } = await patchRequest(
			path,
			{},
			{
				withCredentials: true,
			}
		);
		if (!error) {
			playlist.pinned = !playlist.pinned;
			if (playlist.pinned) {
				commit("ADD_PINNED_PLAYLIST", playlist);
				commit(
					"DELETE_PLAYLIST",
					state.playlists.findIndex((item) => item.id === playlist.id)
				);
			} else {
				commit("ADD_PLAYLIST", playlist);
				commit(
					"DELETE_PINNED_PLAYLIST",
					state.pinnedPlaylists.findIndex(
						(item) => item.id === playlist.id
					)
				);
			}

			return { error: false };
		}
		return { error: true, message: message };
	},
};

const getters = {
	playlists: (state) => state.playlists,
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
};
