import { getRequest, postRequest } from "@/helpers/index.js";
import getUrl from "@/constants/api/index.js";

const state = {
	licenses: [],
};

const mutations = {
	SET_LICENSES: function (state, licenses) {
		state.licenses = licenses;
	},
	APPEND_LICENSES: function (state, licenses) {
		state.licenses.push(...licenses);
	},
	RESET_LICENSES_STATE: function (state) {
		state.licenses = [];
	},
};

const actions = {
	async getLicenses(context, { policyId, yearId, pointOfSaleId }) {
		let url = getUrl("licenses");
		url += `?policyId=${policyId}&yearId=${yearId}&pointOfSaleId=${pointOfSaleId}`;

		const { data, error, message } = await getRequest(url, {
			withCredentials: true,
		});

		if (!error) {
			context.commit("SET_LICENSES", data);
		}
		return { data, error, message };
	},
	async generateLicenses(context, input) {
		const { error, message } = await postRequest(
			`${getUrl("licenses")}/generate-licenses`,
			input,
			{
				withCredentials: true,
			}
		);
		return { error, message };
	},
};

const getters = {
	licenses: (state) => state.licenses,
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
};
