import { getRequest } from "@/helpers/index.js";
import getUrl from "@/constants/api/index.js";

const state = {
	roles: [],
};

const mutations = {
	SET_ROLES: function (state, roles) {
		state.roles = roles;
	},
	RESET_ROLES_STATE: function (state) {
		state.roles = [];
	},
};

const actions = {
	getRoles: async function (context) {
		const { error, data } = await getRequest(getUrl("roles"), {
			withCredentials: true,
		});
		if (!error) {
			context.commit("SET_ROLES", data);
		}
	},
};

const getters = {
	roles: (state) => state.roles,
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
};
