// eslint-disable-next-line no-undef
const BASE_URL = process.env.VUE_APP_ENV === "development"
		? "http://localhost:60070/api"
		: "https://medlibro.co/api";

const urls = [
	{
		version: 1,
		name: "auth",
		path: "auth",
	},
	{
		version: 1,
		name: "users",
		path: "users",
	},
	{
		version: 1,
		name: "roles",
		path: "roles",
	},
	{
		version: 1,
		name: "configurations",
		path: "configurations",
	},
	{
		version: 1,
		name: "utils",
		path: "utils",
	},
	{
		version: 1,
		name: "years",
		path: "years",
	},
	{
		version: 1,
		name: "locations",
		path: "locations",
	},
	{
		version: 1,
		name: "sources",
		path: "sources",
	},
	{
		version: 1,
		name: "themes",
		path: "themes",
	},
	{
		version: 1,
		name: "chapters",
		path: "chapters",
	},
	{
		version: 1,
		name: "courses",
		path: "courses",
	},
	{
		version: 1,
		name: "clinicalCases",
		path: "clinical-cases",
	},
	{
		version: 1,
		name: "questions",
		path: "questions",
	},
	{
		version: 1,
		name: "images",
		path: "images",
	},
	{
		version: 1,
		name: "learn",
		path: "learn",
	},
	{
		version: 1,
		name: "notes",
		path: "notes",
	},
	{
		version: 1,
		name: "playlists",
		path: "playlists",
	},
	{
		version: 1,
		name: "results",
		path: "results",
	},
	{
		version: 1,
		name: "policies",
		path: "policies",
	},
	{
		version: 1,
		name: "entitlements",
		path: "entitlements",
	},
	{
		version: 1,
		name: "pointOfSales",
		path: "point-of-sales",
	},
	{
		version: 1,
		name: "licenses",
		path: "licenses",
	},
	{
		version: 1,
		name: "payments",
		path: "payments",
	},
	{
		version: 1,
		name: "revision",
		path: "revision",
	},
	{
		version: 1,
		name: "exam",
		path: "exam",
	},
	{
		version: 1,
		name: "contribution",
		path: "contribution",
	},
	{
		version: 1,
		name: "books",
		path: "books",
	},
	{
		version: 1,
		name: "reports",
		path: "reports",
	},
	{
		version: 1,
		name: "prioritizer",
		path: "prioritizer",
	},
	{
		version: 1,
		name: "cards",
		path: "cards",
	},
	{
		version: 1,
		name: "preferences",
		path: "preferences",
	},
	{
		version: 1,
		name: "paymentAccounts",
		path: "payment-accounts",
	},
	{
		version: 1,
		name: "posts",
		path: "posts"
	}
];

export default function getUrl(name) {
	const target = urls.find((url) => url.name === name);
	return `${BASE_URL}/v${target.version}/${target.path}`;
}
