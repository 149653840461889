import { getRequest, postRequest } from "@/helpers/index.js";
import getUrl from "@/constants/api/index.js";
import Vue from "vue";

const state = {
	loadingNewItem: false,
	isRevision: false,
	isExam: false,
	isPlaylist: false,
	revisionModes: ["practice", "explore"],
	revisionMode: "practice",
	examModes: ["replica", "adaptive"],
	examMode: "replica",
	thresholds: [25, 50, 75, 100],
	thresholdPerChapter: 25,
	secondsPerQuestionOptions: [30, 60, 90, 120],
	secondsPerQuestion: 60,
	locations: [],
	selectedLocations: [],
	years: [],
	selectedYears: [],
	filterByOptions: ["exam", "unit", "module"],
	filterBy: "exam",
	themes: [],
	selectedThemes: [],
	chapters: [],
	selectedChapters: [],
	courses: [],
	selectedCourses: [],
	sources: [],
	selectedSources: [],
	selectedPlaylist: [],
	itemsIds: [],
	items: [],
	mainIndex: 0,
	secondaryIndex: 0,
	counter: 0,
	totalQuestionsCounter: 0,
};

const mutations = {
	INC_DEC_ATTACHED_TO: function(state, value) {
		state.items[state.mainIndex].attachedTo += value;
	},
	SET_LOADING_NEW_ITEM(state, value) {
		state.loadingNewItem = value;
	},
	SET_IS_REVISION(state, isRevision) {
		state.isRevision = isRevision;
		state.isExam = !isRevision;
		state.isPlaylist = !isRevision;
	},
	SET_IS_EXAM(state, isExam) {
		state.isExam = isExam;
		state.isRevision = !isExam;
		state.isPlaylist = !isExam;
	},
	SET_IS_PLAYLIST(state, isPlaylist) {
		state.isPlaylist = isPlaylist;
		state.isRevision = !isPlaylist;
		state.isExam = !isPlaylist;
	},
	SET_REVISION_MODE(state, revisionMode) {
		state.revisionMode = revisionMode;
	},
	SET_EXAM_MODE(state, examMode) {
		state.examMode = examMode;
	},
	SET_THRESHOLD_PER_CHAPTER(state, thresholdPerChapter) {
		state.thresholdPerChapter = thresholdPerChapter;
	},
	SET_SECONDS_PER_QUESTION(state, secondsPerQuestion) {
		state.secondsPerQuestion = secondsPerQuestion;
	},
	SET_LOCATIONS(state, payload) {
		state.locations = payload;
	},
	SET_SELECTED_LOCATIONS(state, payload) {
		state.selectedLocations = payload;
	},
	SET_YEARS(state, payload) {
		state.years = payload;
	},
	SET_SELECTED_YEARS(state, payload) {
		state.selectedYears = payload;
	},
	SET_FILTER_BY(state, payload) {
		state.filterBy = payload;
	},
	SET_THEMES(state, payload) {
		state.themes = payload;
	},
	SET_SELECTED_THEMES(state, payload) {
		state.selectedThemes = payload;
	},
	SET_CHAPTERS(state, payload) {
		state.chapters = payload;
	},
	SET_SELECTED_CHAPTERS(state, payload) {
		state.selectedChapters = payload;
	},
	SET_COURSES(state, payload) {
		state.courses = payload;
	},
	SET_SELECTED_COURSES(state, payload) {
		state.selectedCourses = payload;
	},
	SET_SOURCES(state, payload) {
		state.sources = payload;
	},
	SET_SELECTED_SOURCES(state, payload) {
		state.selectedSources = payload;
	},
	SET_SELECTED_PLAYLIST(state, payload) {
		state.selectedPlaylist = payload;
	},
	SET_ITEMS_IDS(state, payload) {
		state.itemsIds = payload;
	},
	SET_ITEMS: function(state, items) {
		state.items = items;
	},
	UPDATE_MAIN_INDEX: function(state, value) {
		state.mainIndex = value;
	},
	UPDATE_SECONDARY_INDEX: function(state, value) {
		state.secondaryIndex = value;
	},
	RESET_ITEMS_STATE: function(state) {
		state.isRevision = false;
		state.isExam = false;
		state.isPlaylist = false;
		state.itemsIds = [];
		state.items = [];
		state.mainIndex = 0;
		state.secondaryIndex = 0;
	},
	SET_COUNTER(state, value) {
		state.counter = value;
	},
	SET_TOTAL_QUESTIONS_COUNTER(state, value) {
		state.totalQuestionsCounter = value;
	},
};

const actions = {
	async getLocations(context) {
		const { data, error, message } = await postRequest(
			getUrl("locations"),
			null,
			{
				withCredentials: true,
			},
		);
		if (!error) {
			context.commit("SET_LOCATIONS", data);
			return { error };
		} else {
			return { error, message };
		}
	},
	async getYears(context) {
		const { data, error, message } = await postRequest(
			getUrl("years"),
			null,
			{
				withCredentials: true,
			},
		);
		if (!error) {
			context.commit("SET_YEARS", data);
			return { error };
		} else {
			return { error, message };
		}
	},
	async getThemes({ state, commit }) {
		if (state.selectedYears?.length === 0) {
			return;
		}

		if (
			state.examMode === "replica" &&
			state.filterBy === "unit" &&
			state.selectedYears[0]?.label === "residency"
		) {
			const { data, error, message } = await getRequest(
				`${getUrl("themes")}/units/${state.selectedYears[0]?.id}`,
				{
					withCredentials: true,
				},
			);

			if (!error) {
				commit("SET_THEMES", data);
				return { error };
			} else {
				return { error, message };
			}
		}

		const { data, error, message } = await postRequest(
			getUrl("themes"),
			{
				yearId: state.selectedYears[0]?.id,
				parentId: null,
				all: true,
			},
			{
				withCredentials: true,
			},
		);
		if (!error) {
			commit("SET_THEMES", data);
			return { error };
		} else {
			return { error, message };
		}
	},
	async getChapters(context) {
		if (context.state.selectedThemes?.length === 0) {
			return;
		}

		const { data, error, message } = await postRequest(
			getUrl("chapters"),
			{
				themeId: context.state.selectedThemes[0]?.id,
			},
			{
				withCredentials: true,
			},
		);
		if (!error) {
			context.commit("SET_CHAPTERS", data);
			return { error };
		} else {
			return { error, message };
		}
	},
	async getCourses(context) {
		if (
			context.state.selectedThemes?.length === 0 ||
			context.state.selectedChapters?.length === 0
		) {
			return;
		}

		const chapters = context.state.isExam
			? context.state.selectedChapters?.map((c) => c.id)
			: [context.state.selectedChapters[0]?.id];

		const { data, error, message } = await postRequest(
			getUrl("courses"),
			{
				theme: context.state.selectedThemes[0]?.id,
				chapters: chapters,
			},
			{
				withCredentials: true,
			},
		);
		if (!error) {
			context.commit("SET_COURSES", data);
			return { error };
		} else {
			return { error, message };
		}
	},
	async getSources({ state, commit }) {
		if (state.isExam && state.examMode === "replica") {
			if (
				(state.filterBy === "exam" || state.filterBy === "unit") &&
				state.selectedYears[0]?.label === "residency"
			) {
				if (
					state.selectedLocations?.length === 0 ||
					state.selectedYears?.length === 0
				) {
					return;
				}

				const url = `${getUrl("sources")}/year/${
					state.selectedYears[0]?.id
				}`;

				const { data, error, message } = await getRequest(url, {
					withCredentials: true,
				});

				if (!error) {
					commit("SET_SOURCES", data);
					return { error };
				}
				return { error, message };
			}

			if (
				state.selectedLocations?.length === 0 ||
				state.selectedThemes?.length === 0
			) {
				return;
			}

			const url = `${getUrl("sources")}/theme/${
				state.selectedThemes[0]?.id
			}`;
			const { data, error, message } = await getRequest(url, {
				withCredentials: true,
			});

			if (!error) {
				commit("SET_SOURCES", data);
				return { error };
			}
			return { error, message };
		}

		if (
			state.selectedLocations?.length === 0 ||
			state.selectedThemes?.length === 0 ||
			state.selectedChapters?.length === 0 ||
			(Array.isArray(state.selectedCourses) &&
				state.selectedCourses?.length === 0)
		) {
			return;
		}

		const chapters = state.isExam
			? state.selectedChapters?.map((c) => c.id)
			: [state.selectedChapters[0]?.id];

		const { data, error, message } = await postRequest(
			`${getUrl("sources")}/learn`,
			{
				location: state.selectedLocations[0]?.id,
				theme: state.selectedThemes[0]?.id,
				chapters: chapters,
				courses: state.selectedCourses?.map((item) => item.id),
			},
			{
				withCredentials: true,
			},
		);

		if (!error) {
			commit("SET_SOURCES", data);
			return { error };
		} else {
			return { error, message };
		}
	},
	async getItems({ state, commit, dispatch }) {
		let url = null;
		let body = null;

		let data, error, message;

		if (state.isExam) {
			if (state.examMode === "replica") {
				if (
					(state.filterBy === "exam" || state.filterBy === "unit") &&
					state.selectedYears[0]?.label === "residency"
				) {
					if (
						state.selectedLocations.length === 0 ||
						state.selectedYears.length === 0 ||
						state.selectedSources.length === 0
					) {
						return {
							error: true,
							message: "selectAllRequiredItems",
						};
					}

					const yearId = state.selectedYears[0]?.id;
					const sourceYear = state.selectedSources[0]?.year;

					url = url = `${getUrl(
						"exam",
					)}/year/${yearId}/source/${sourceYear}`;

					const params = new URLSearchParams();

					if (state.filterBy === "unit") {
						if (state.selectedThemes.length === 0) {
							return {
								error: true,
								message: "selectAllRequiredItems",
							};
						}

						const unitId = state.selectedThemes[0]?.id;
						params.append("unitId", unitId);

						url = `${url}?${params.toString()}`;
					}

					({ data, error, message } = await getRequest(url, {
						withCredentials: true,
					}));
				} else {
					if (
						state.selectedLocations.length === 0 ||
						state.selectedYears.length === 0 ||
						state.selectedThemes.length === 0 ||
						state.selectedSources.length === 0
					) {
						return {
							error: true,
							message: "selectAllRequiredItems",
						};
					}

					const params = new URLSearchParams();
					params.append("themeId", state.selectedThemes[0]?.id);
					url = `${getUrl("exam")}/source/${
						state.selectedSources[0]?.id
					}`;
					if (params.toString().length > 0) {
						url = `${url}?${params.toString()}`;
					}

					({ data, error, message } = await getRequest(url, {
						withCredentials: true,
					}));
				}
			} else if (state.examMode === "adaptive") {
				if (
					state.selectedLocations.length === 0 ||
					state.selectedYears.length === 0 ||
					state.selectedThemes.length === 0 ||
					state.selectedChapters.length === 0 ||
					state.selectedCourses.length === 0 ||
					state.selectedSources.length === 0
				) {
					return { error: true, message: "selectAllRequiredItems" };
				}

				url = getUrl("exam");
				body = {
					locationId: state.selectedLocations[0]?.id,
					themeId: state.selectedThemes[0]?.id,
					chaptersIds: state.selectedChapters.map((c) => c.id),
					coursesIds: state.selectedCourses.map((item) => item.id),
					sourcesYears: state.selectedSources.map(
						(item) => item.year,
					),
					thresholdPerChapter: state.thresholdPerChapter,
				};

				({ data, error, message } = await postRequest(url, body, {
					withCredentials: true,
				}));
			}
		} else if (state.isRevision) {
			if (
				state.selectedLocations.length === 0 ||
				state.selectedYears.length === 0 ||
				state.selectedThemes.length === 0 ||
				state.selectedChapters.length === 0 ||
				state.selectedCourses.length === 0 ||
				state.selectedSources.length === 0
			) {
				return { error: true, message: "selectAllRequiredItems" };
			}

			url = getUrl("revision");
			body = {
				locationId: state.selectedLocations[0]?.id,
				themeId: state.selectedThemes[0]?.id,
				chapterId: state.selectedChapters[0]?.id,
				coursesIds: state.selectedCourses.map((item) => item.id),
				sourcesYears: state.selectedSources.map((item) => item.year),
			};
			({ data, error, message } = await postRequest(url, body, {
				withCredentials: true,
			}));
		} else if (state.isPlaylist) {
			const items = [
				...state.selectedPlaylist.questions.map((item) => {
					return { entity: "question", id: item.id };
				}),
				...state.selectedPlaylist.clinicalCases.map((item) => {
					return { entity: "clinicalCase", id: item.id };
				}),
			];
			commit("SET_ITEMS_IDS", items);
			commit("SET_COUNTER", items?.length);
			commit(
				"SET_TOTAL_QUESTIONS_COUNTER",
				state.selectedPlaylist.totalQuestionsCount,
			);
		}

		if (!error) {
			if (!state.isPlaylist) {
				let { items, totalQuestionsCounter } = data;

				commit("SET_ITEMS_IDS", items);
				commit("SET_COUNTER", items?.length);
				commit("SET_TOTAL_QUESTIONS_COUNTER", totalQuestionsCounter);
			}

			await dispatch("getItem");
		}
		return { error, message };
	},
	async getItem(context) {
		const loadItem = async (item) => {
			try {
				let url = null;

				switch (item.entity) {
					case "question":
						url = `${getUrl("questions")}/${item.id}`;
						break;
					case "clinicalCase":
						url = `${getUrl("clinicalCases")}/${item.id}`;
						break;
				}

				let { data, error, message } = await getRequest(url, {
					withCredentials: true,
				});

				if (data.images) {
					for (const image of data.images) {
						// eslint-disable-next-line no-undef
						const buffer = Buffer.from(
							image.file["Body"].data,
						)?.toString("base64");
						image.src = `data:image/png;base64,${buffer}`;
					}
				}
				if (item.entity === "clinicalCase") {
					for (let subItem of data.questions) {
						if (subItem.images) {
							for (const image of subItem.images) {
								// eslint-disable-next-line no-undef
								const buffer = Buffer.from(
									image.file["Body"].data,
								)?.toString("base64");
								image.src = `data:image/png;base64,${buffer}`;
							}
						}
					}
				}

				if (!error) {
					context.commit("SET_ITEMS", [
						...context.state.items,
						{ entity: item.entity, ...data },
					]);
				}

				return { error, message, data };
			} catch (error) {
				context.commit("SET_LOADING_NEW_ITEM", false);
				return { error, message: error.message };
			}
		};

		if (context.state.loadingNewItem) {
			return { error: true, message: "loadingNewItem" };
		}

		if (context.state.items?.length > 0) {
			if (
				context.state.items[context.state.mainIndex].entity ===
				"clinicalCase"
			) {
				if (
					context.state.secondaryIndex <
					context.state.items[context.state.mainIndex].questions
						.length -
					1
				) {
					return { error: true, message: "navigatingSubItems" };
				}
			}
		}

		const timer = setInterval(async function() {
			if (navigator.onLine) {
				if (context.state.itemsIds.length > 0) {
					context.commit("SET_LOADING_NEW_ITEM", true);
					const item = context.state.itemsIds.splice(0, 1).pop();
					await loadItem(item);
					context.commit("SET_LOADING_NEW_ITEM", false);
				} else {
					clearInterval(timer);
				}
			} else {
				clearInterval(timer);
			}
		}, 300);
	},
	async endExam(context) {
		for (let i = 0; i < context.state.items.length; i++) {
			if (
				context.state.items[i].entity === "question" &&
				!context.state.items[i].done
			) {
				Vue.set(context.state.items[i], "answerStatus", "missed");
				Vue.set(context.state.items[i], "done", true);
			} else if (context.state.items[i].entity === "clinicalCase") {
				for (
					let j = 0;
					j < context.state.items[i].questions.length;
					j++
				) {
					if (!context.state.items[i].questions[j].done) {
						Vue.set(
							context.state.items[i].questions[j],
							"answerStatus",
							"missed",
						);
						Vue.set(
							context.state.items[i].questions[j],
							"done",
							true,
						);
					}
				}
			}
		}
		return true;
	},
	navigateToPreviousItem(context) {
		if (context.getters.previousSecondaryItem) {
			if (context.getters.currentMainItem.entity === "clinicalCase") {
				context.commit(
					"UPDATE_SECONDARY_INDEX",
					parseInt(context.state.secondaryIndex) - 1,
				);
			}
		} else if (context.getters.previousMainItem) {
			if (context.getters.previousMainItem.entity === "clinicalCase") {
				context.commit(
					"UPDATE_SECONDARY_INDEX",
					parseInt(
						context.getters.previousMainItem.questions.length,
					) - 1,
				);
			} else {
				context.commit("UPDATE_SECONDARY_INDEX", 0);
			}
			context.commit(
				"UPDATE_MAIN_INDEX",
				parseInt(context.state.mainIndex) - 1,
			);
		}
	},
	async navigateToNextItem(context) {
		if (context.getters.currentSecondaryItem) {
			if (context.getters.nextSecondaryItem) {
				context.commit(
					"UPDATE_SECONDARY_INDEX",
					context.state.secondaryIndex + 1,
				);
			} else if (context.getters.nextMainItem) {
				context.commit(
					"UPDATE_MAIN_INDEX",
					context.state.mainIndex + 1,
				);
				context.commit("UPDATE_SECONDARY_INDEX", 0);
			}
		} else {
			if (context.getters.nextMainItem) {
				context.commit(
					"UPDATE_MAIN_INDEX",
					context.state.mainIndex + 1,
				);
				context.commit("UPDATE_SECONDARY_INDEX", 0);
			}
		}
	},
	async skipCurrentItem(context) {
		if (context.getters.currentSecondaryItem) {
			if (!context.getters.currentSecondaryItem?.done) {
				Vue.set(
					context.state.items[context.state.mainIndex].questions[
						context.state.secondaryIndex
						],
					"answerStatus",
					"skipped",
				);
				Vue.set(
					context.state.items[context.state.mainIndex].questions[
						context.state.secondaryIndex
						],
					"done",
					true,
				);
				Vue.set(
					context.state.items[context.state.mainIndex].questions[
						context.state.secondaryIndex
						],
					"answer",
					[],
				);
			}
			if (context.getters.nextSecondaryItem) {
				context.commit(
					"UPDATE_SECONDARY_INDEX",
					context.state.secondaryIndex + 1,
				);
			} else if (context.getters.nextMainItem) {
				context.commit(
					"UPDATE_MAIN_INDEX",
					context.state.mainIndex + 1,
				);
				context.commit("UPDATE_SECONDARY_INDEX", 0);
			}
		} else {
			if (!context.getters.currentMainItem?.done) {
				Vue.set(
					context.state.items[context.state.mainIndex],
					"answerStatus",
					"skipped",
				);
				Vue.set(
					context.state.items[context.state.mainIndex],
					"done",
					true,
				);
				Vue.set(
					context.state.items[context.state.mainIndex],
					"answer",
					[],
				);
			}
			if (context.getters.nextMainItem) {
				context.commit(
					"UPDATE_MAIN_INDEX",
					context.state.mainIndex + 1,
				);
				context.commit("UPDATE_SECONDARY_INDEX", 0);
			}
		}
	},
	addNote(context, note) {
		if (context.getters.currentSecondaryItem) {
			const copy =
				context.state.items[context.state.mainIndex].questions[
					context.state.secondaryIndex
					];
			Vue.set(
				context.state.items[context.state.mainIndex].questions[
					context.state.secondaryIndex
					],
				"notes",
				[...copy.notes, note],
			);
		} else {
			const copy = context.state.items[context.state.mainIndex];
			Vue.set(context.state.items[context.state.mainIndex], "notes", [
				...copy.notes,
				note,
			]);
		}
	},
	editNote(context, updatedNote) {
		if (context.getters.currentSecondaryItem) {
			let copy =
				context.state.items[context.state.mainIndex].questions[
					context.state.secondaryIndex
					];
			copy = [
				...copy.notes.filter((item) => item.id !== updatedNote.id),
				updatedNote,
			];
			Vue.set(
				context.state.items[context.state.mainIndex].questions[
					context.state.secondaryIndex
					],
				"notes",
				copy,
			);
		} else {
			let copy = context.state.items[context.state.mainIndex];
			copy = [
				...copy.notes.filter((item) => item.id !== updatedNote.id),
				updatedNote,
			];
			Vue.set(
				context.state.items[context.state.mainIndex],
				"notes",
				copy,
			);
		}
	},
	deleteNote(context, note) {
		if (context.getters.currentSecondaryItem) {
			Vue.set(
				context.state.items[context.state.mainIndex].questions[
					context.state.secondaryIndex
					],
				"notes",
				context.state.items[context.state.mainIndex].questions[
					context.state.secondaryIndex
					].notes.filter((item) => item.id !== note.id),
			);
		} else {
			Vue.set(
				context.state.items[context.state.mainIndex],
				"notes",
				context.state.items[context.state.mainIndex].notes.filter(
					(item) => item.id !== note.id,
				),
			);
		}
	},
};

const getters = {
	loadingNewItem: (state) => state.loadingNewItem,
	isRevision: (state) => state.isRevision,
	isExam: (state) => state.isExam,
	isPlaylist: (state) => state.isPlaylist,
	selectedPlaylist: (state) => state.selectedPlaylist,
	thresholds: (state) => state.thresholds,
	thresholdPerChapter: (state) => state.thresholdPerChapter,
	secondsPerQuestion: (state) => state.secondsPerQuestion,
	locations: (state) => state.locations,
	selectedLocations: (state) => state.selectedLocations,
	years: (state) => state.years,
	selectedYears: (state) => state.selectedYears,
	themes: (state) => state.themes,
	selectedThemes: (state) => state.selectedThemes,
	chapters: (state) => state.chapters,
	selectedChapters: (state) => state.selectedChapters,
	courses: (state) => state.courses,
	selectedCourses: (state) => state.selectedCourses,
	sources: (state) => state.sources,
	selectedSources: (state) => state.selectedSources,
	items: (state) => state.items,
	previousMainItem: (state) => {
		const decremented = parseInt(state.mainIndex) - 1;
		if (decremented < 0) {
			return null;
		}
		return state.items[decremented];
	},
	previousSecondaryItem: (state, getters) => {
		const decremented = parseInt(state.secondaryIndex) - 1;
		if (decremented < 0) {
			return null;
		}
		return getters.currentMainItem.questions[decremented];
	},
	currentMainItem: (state) => state.items[state.mainIndex],
	currentSecondaryItem: (state, getters) => {
		if (getters.currentMainItem?.entity === "clinicalCase") {
			return getters.currentMainItem.questions[state.secondaryIndex];
		}
		return null;
	},
	nextMainItem: (state) => {
		const incremented = state.mainIndex + 1;
		return state.items[incremented];
	},
	nextSecondaryItem: (state, getters) => {
		if (getters.currentMainItem?.entity === "clinicalCase") {
			const incremented = state.secondaryIndex + 1;
			return getters.currentMainItem.questions[incremented];
		}
		return null;
	},
	clinicalCase: (state, getters) => {
		return getters.currentSecondaryItem ? getters.currentMainItem : null;
	},
	question: (state, getters) => {
		return getters.currentSecondaryItem
			? getters.currentSecondaryItem
			: getters.currentMainItem;
	},
	hidePrevious: (state, getters) => {
		return !getters.previousMainItem && !getters.previousSecondaryItem;
	},
	hideCheck: (state, getters) => {
		return (
			getters.currentMainItem?.done || getters.currentSecondaryItem?.done
		);
	},
	hideSkip: (state, getters) => {
		return (
			getters.currentMainItem?.done || getters.currentSecondaryItem?.done
		);
	},
	hideNext: (state, getters) => {
		return (
			!getters.nextMainItem &&
			!getters.nextSecondaryItem &&
			state.itemsIds.length === 0
		);
	},
	examEndsAt: (state) => {
		if (!state.isExam) {
			return 0;
		}
		const currentTime = new Date().getTime();
		let questionsCounter = state.totalQuestionsCounter;
		return currentTime + state.secondsPerQuestion * 1000 * questionsCounter;
	},
	done: (state) => {
		let completed = true;
		for (let item in state.items) {
			if (
				state.items[item].entity === "question" &&
				!state.items[item].done
			) {
				completed = false;
				break;
			} else if (state.items[item].entity === "clinicalCase") {
				for (let question in state.items[item].questions) {
					if (!state.items[item].questions[question].done) {
						completed = false;
						break;
					}
				}
			}
		}
		return completed;
	},
	counter: (state) => state.counter,
	totalQuestionsCounter: (state) => state.totalQuestionsCounter,
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
};
