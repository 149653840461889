import Vue from "vue";
import Vuex from "vuex";

import { getRequest } from "@/helpers/index.js";
import getUrl from "@/constants/api/index.js";

import auth from "@/store/modules/auth.js";
import users from "@/store/modules/users.js";
import roles from "@/store/modules/roles.js";
import configurations from "@/store/modules/configurations.js";
import locations from "@/store/modules/locations.js";
import themes from "@/store/modules/themes.js";
import chapters from "@/store/modules/chapters.js";
import courses from "@/store/modules/courses.js";
import clinicalCases from "@/store/modules/clinical-cases.js";
import questions from "@/store/modules/questions.js";
import years from "@/store/modules/years.js";
import playlists from "@/store/modules/playlists.js";
import pointOfSales from "@/store/modules/point-of-sales.js";
import licenses from "@/store/modules/licenses.js";
import selection from "@/store/modules/selection.js";
import contribution from "@/store/modules/contribution.js";
import prioritizer from "@/store/modules/prioritizer";

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		loadingScreen: true,
		preferences: [],
		dueCardsCount: 0,
		latestBlogPosts: [],
	},
	mutations: {
		SET_LOADING_SCREEN(state, loadingScreen) {
			state.loadingScreen = loadingScreen;
		},
		SET_PREFERENCES(state, preferences) {
			state.preferences = preferences;
		},
		DECREMENT_DUE_CARDS_COUNT(state, number = 1) {
			state.dueCardsCount -= number;
		},
	},
	actions: {
		async fetchPreferences({ state }) {
			const url = `${getUrl("preferences")}`;
			const { data, error } = await getRequest(url, {
				withCredentials: true,
			});
			if (!error) {
				state.preferences = data;
			}
		},
		async fetchDueCardsCount({ state }) {
			const hideDueCardsCountPreference = state.preferences?.find(
				(preference) => preference.label === "hide-due-cards-count",
			);

			if (hideDueCardsCountPreference?.value === "true") return;

			const url = `${getUrl("cards")}/due/count`;
			const { data, error } = await getRequest(url, {
				withCredentials: true,
			});
			if (!error) {
				state.dueCardsCount = data;
			}
		},
		async loadLatestBlogPosts({ state }) {
			if (state.latestBlogPosts.length > 0) {
				return;
			}
			const { data } = await getRequest(
				`${getUrl("posts")}/latest`,
				{
					withCredentials: true,
				},
			);
			state.latestBlogPosts = data;
		},
	},
	modules: {
		auth: auth,
		users: users,
		roles: roles,
		configurations: configurations,
		years: years,
		locations: locations,
		themes: themes,
		chapters: chapters,
		courses: courses,
		clinicalCases: clinicalCases,
		questions: questions,
		playlists: playlists,
		pointOfSales: pointOfSales,
		licenses: licenses,
		selection: selection,
		contribution: contribution,
		prioritizer: prioritizer,
	},
});
