import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import fr from "vuetify/lib/locale/fr";
import "@/mixins.js";
import "@/styles/index.sass";
import VueAwesomeCountdown from "vue-awesome-countdown";

import "vue-calendar-heatmap/dist/vue-calendar-heatmap.css";
import VueCalendarHeatmap from "vue-calendar-heatmap/dist/vue-calendar-heatmap.common";

Vue.use(VueCalendarHeatmap);

Vue.use(Vuetify);
Vue.use(VueAwesomeCountdown, "vue-awesome-countdown");

export default new Vuetify({
	lang: {
		locales: { fr },
		current: "fr",
	},
	theme: {
		themes: {
			light: {
				opso: "#EF4035",
				medlibro: "#20A0D8",
				primary: "#20A0D8",
				error: "#ED1C24",
				success: "#5FAD56",
				accent: "#9871ec",
				border: "#F5F5F5",
				background: "#ffffff",
				binary: "#000000",
				"binary-reverse": "#ffffff",
			},
			dark: {
				opso: "#ffffff",
				medlibro: "#ffffff",
				primary: "#20A0D8",
				success: "#5FAD56",
				error: "#ED1C24",
				accent: "#9871ec",
				border: "#121212",
				background: "#000000",
				binary: "#ffffff",
				"binary-reverse": "#000000",
			},
		},
		options: { customProperties: true },
	},
});
