import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store/index.js";

const NotFoundPage = () => import("@/views/pages/NotFoundPage.vue");
const SignupPage = () => import("@/views/pages/SignupPage.vue");
const LoginPage = () => import("@/views/pages/LoginPage.vue");
const HomePage = () => import("@/views/pages/HomePage.vue");
const ForgotPasswordPage = () => import("@/views/pages/ForgotPasswordPage.vue");
const FAQPage = () => import("@/views/pages/FAQPage.vue");
const UsersPage = () => import("@/views/pages/UsersPage.vue");
const StatisticsPage = () => import("@/views/pages/StatisticsPage.vue");
const RevisionPage = () => import("@/views/pages/RevisionPage.vue");
const ProfilePage = () => import("@/views/pages/ProfilePage.vue");
const PlaylistsPage = () => import("@/views/pages/PlaylistsPage.vue");
const PricingPage = () => import("@/views/pages/PricingPage.vue");
const DashboardPage = () => import("@/views/pages/DashboardPage.vue");
const ExamPage = () => import("@/views/pages/ExamPage.vue");
const ContributionPage = () => import("@/views/pages/ContributionPage.vue");
const ReportsPage = () => import("@/views/pages/ReportsPage.vue");
const PrioritizerPage = () => import("@/views/pages/PrioritizerPage.vue");
const MemorixPage = () => import("@/views/pages/MemorixPage.vue");
const PostsPage = () => import("@/views/pages/PostsPage.vue");
const CardsPage = () => import("@/views/pages/CardsPage.vue");

import {
	CLIENT_LAYOUT,
	POWER_LAYOUT,
	DEFAULT_LAYOUT,
} from "@/constants/index.js";

// NEXT
const FilterPage = () => import("@/next/pages/FilterPage.vue");
const SessionsPage = () => import("@/next/pages/SessionsPage.vue");
const SessionPage = () => import("@/next/pages/SessionPage.vue");
const QuestionPage = () => import("@/next/pages/QuestionPage.vue");
const CasePage = () => import("@/next/pages/CasePage.vue");
const FavoritesPage = () => import("@/next/pages/FavoritesPage.vue");
const ActivatePage = () => import("@/next/pages/ActivatePage.vue");
const ErrorPage = () => import("@/next/pages/ErrorPage.vue");

import { FOCUS_LAYOUT } from "@/next/constants";

Vue.use(VueRouter);

const isAuthenticated = () => {
	return store.getters["auth/isAuthenticated"];
};

const isPremium = () => {
	return store.getters["auth/isPremium"];
};

const isValid = () => {
	return store.getters["auth/connectedUser"]?.status === "valid";
};

const resetData = async () => {
	return store.commit("selection/RESET_ITEMS_STATE", null, { root: true });
};

const defaultLayoutRoutes = [
	{
		path: "/",
		name: "/",
		component: HomePage,
		meta: {
			layout: DEFAULT_LAYOUT,
		},
		async beforeEnter(to, from, next) {
			await resetData();
			next();
		},
	},
	{
		path: "/home",
		redirect: "/",
	},
	{
		path: "/order",
		redirect: "/pricing",
	},
	{
		path: "/pricing",
		name: "pricing",
		component: PricingPage,
		meta: {
			layout: DEFAULT_LAYOUT,
		},
	},
	{
		path: "/faq",
		name: "faq",
		component: FAQPage,
		meta: {
			layout: DEFAULT_LAYOUT,
		},
		async beforeEnter(to, from, next) {
			await resetData();
			next();
		},
	},
	{
		path: "/login",
		name: "login",
		component: LoginPage,
		meta: {
			layout: DEFAULT_LAYOUT,
		},
		async beforeEnter(to, from, next) {
			if (isAuthenticated()) {
				next({ name: "dashboard" });
			} else {
				next();
			}
		},
	},
	{
		path: "/signup",
		name: "signup",
		component: SignupPage,
		meta: {
			layout: DEFAULT_LAYOUT,
		},
		async beforeEnter(to, from, next) {
			if (isAuthenticated()) {
				next({ name: "dashboard" });
			} else {
				next();
			}
		},
	},
	{
		path: "/forgot-password",
		name: "forgotPassword",
		component: ForgotPasswordPage,
		meta: {
			layout: DEFAULT_LAYOUT,
		},
		async beforeEnter(to, from, next) {
			if (isAuthenticated()) {
				return next({ name: "/" });
			}
			next();
		},
	},
	{
		path: "/error",
		name: "error",
		component: ErrorPage,
		meta: {
			layout: DEFAULT_LAYOUT,
		},
	},
];

const clientLayoutRoutes = [
	{
		path: "/dashboard",
		name: "dashboard",
		meta: {
			layout: CLIENT_LAYOUT,
		},
		component: DashboardPage,
		async beforeEnter(to, from, next) {
			if (isAuthenticated()) {
				await resetData();
				next();
			} else {
				next({ name: "/" });
			}
		},
	},
	{
		path: "/filter",
		name: "filter",
		component: FilterPage,
		meta: {
			layout: CLIENT_LAYOUT,
		},
		async beforeEnter(to, from, next) {
			if (isAuthenticated()) {
				if (isValid()) {
					await resetData();
					return next();
				}
				return next({ name: "dashboard" });
			}
			next({ name: "/" });
		},
	},
	{
		path: "/sessions",
		name: "sessions",
		component: SessionsPage,
		meta: {
			layout: CLIENT_LAYOUT,
		},
		async beforeEnter(to, from, next) {
			const guard = store.getters["auth/isAuthenticated"];
			if (guard) {
				if (isValid()) {
					return next();
				}
				return next({ name: "dashboard" });
			}
			next({ name: "/" });
		},
	},
	{
		path: "/favorites",
		name: "favorites",
		component: FavoritesPage,
		meta: {
			layout: CLIENT_LAYOUT,
		},
		async beforeEnter(to, from, next) {
			const guard = store.getters["auth/isAuthenticated"] && store.getters["auth/isAdmin"];
			if (guard) {
				if (isValid()) {
					await resetData();
					return next();
				}
				return next({ name: "dashboard" });
			}
			next({ name: "/" });
		},
	},
	{
		path: "/revision",
		name: "revision",
		component: RevisionPage,
		meta: {
			layout: CLIENT_LAYOUT,
		},
		async beforeEnter(to, from, next) {
			if (isAuthenticated()) {
				if (isValid()) {
					await resetData();
					return next();
				}
				return next({ name: "dashboard" });
			}
			next({ name: "/" });
		},
	},
	{
		path: "/exam",
		name: "exam",
		component: ExamPage,
		meta: {
			layout: CLIENT_LAYOUT,
		},
		async beforeEnter(to, from, next) {
			if (isAuthenticated()) {
				if (isValid() && isPremium()) {
					await resetData();
					return next();
				}
				return next({ name: "dashboard" });
			}
			next({ name: "/" });
		},
	},
	{
		path: "/memorix",
		name: "memorix",
		component: MemorixPage,
		meta: {
			layout: CLIENT_LAYOUT,
		},
		async beforeEnter(to, from, next) {
			if (isAuthenticated()) {
				if (isValid() && isPremium()) {
					await resetData();
					return next();
				}
				return next({ name: "dashboard" });
			}
			next({ name: "/" });
		},
	},
	{
		path: "/memorix/:themeId",
		name: "cards",
		component: CardsPage,
		meta: {
			layout: CLIENT_LAYOUT,
		},
		async beforeEnter(to, from, next) {
			if (isAuthenticated()) {
				if (isValid() && isPremium()) {
					await resetData();
					return next();
				}
				return next({ name: "dashboard" });
			}
			next({ name: "/" });
		},
	},
	{
		path: "/prioritizer",
		name: "prioritizer",
		meta: {
			layout: CLIENT_LAYOUT,
		},
		component: PrioritizerPage,
		async beforeEnter(to, from, next) {
			if (isAuthenticated()) {
				if (isValid() && isPremium()) {
					await resetData();
					return next();
				}
				return next({ name: "dashboard" });
			}
			next({ name: "/" });
		},
	},
	{
		path: "/playlists",
		name: "playlists",
		component: PlaylistsPage,
		meta: {
			layout: CLIENT_LAYOUT,
		},
		async beforeEnter(to, from, next) {
			if (isAuthenticated()) {
				if (!to.params.play && isValid() && isPremium()) {
					await resetData();
					return next();
				}
				return next({ name: "dashboard" });
			} else {
				next({ name: "/" });
			}
		},
	},
	{
		path: "/profile",
		name: "profile",
		component: ProfilePage,
		meta: {
			layout: CLIENT_LAYOUT,
		},
		async beforeEnter(to, from, next) {
			if (isAuthenticated()) {
				next();
			} else {
				next({ name: "/" });
			}
		},
	},
	{
		path: "/activate",
		name: "activate",
		component: ActivatePage,
		meta: {
			layout: CLIENT_LAYOUT,
		},
		async beforeEnter(to, from, next) {
			if (isAuthenticated()) {
				next();
			} else {
				next({ name: "/" });
			}
		},
	},
];

const focusLayoutRoutes = [
	{
		path: "/s/:id",
		name: "session",
		component: SessionPage,
		meta: {
			layout: FOCUS_LAYOUT,
		},
		async beforeEnter(to, from, next) {
			const guard = store.getters["auth/isAuthenticated"];
			if (guard) {
				if (isValid()) {
					return next();
				}
				return next({ name: "dashboard" });
			}
			next({
				name: "login", query: {
					origin: to.path,
				},
			});
		},
	},
	{
		path: "/q/:id",
		name: "q",
		component: QuestionPage,
		meta: {
			layout: FOCUS_LAYOUT,
		},
		async beforeEnter(to, from, next) {
			if (isAuthenticated()) {
				if (isValid()) {
					return next();
				}
				return next({ name: "dashboard" });
			}
			next({
				name: "login", query: {
					origin: to.path,
				},
			});
		},
	},
	{
		path: "/c/:id",
		name: "c",
		component: CasePage,
		meta: {
			layout: FOCUS_LAYOUT,
		},
		async beforeEnter(to, from, next) {
			if (isAuthenticated()) {
				if (isValid()) {
					return next();
				}
				return next({ name: "dashboard" });
			}
			next({
				name: "login", query: {
					origin: to.path,
				},
			});
		},
	},
];

const powerLayoutRoutes = [
	{
		path: "/x/statistics",
		name: "statistics",
		component: StatisticsPage,
		meta: {
			layout: POWER_LAYOUT,
		},
		async beforeEnter(to, from, next) {
			const guard =
				store.getters["auth/isAuthenticated"] &&
				(store.getters["auth/isAdmin"] ||
					store.getters["auth/isModerator"]);
			if (guard) {
				await resetData();
				next();
			} else {
				next({ name: "/" });
			}
		},
	},
	{
		path: "/x/posts",
		name: "posts",
		component: PostsPage,
		meta: {
			layout: POWER_LAYOUT,
		},
		async beforeEnter(to, from, next) {
			const guard =
				store.getters["auth/isAuthenticated"] &&
				store.getters["auth/isAdmin"];
			if (guard) {
				return next();
			}
			next({ name: "/" });
		},
	},
	{
		path: "/x/contribution",
		name: "contribution",
		component: ContributionPage,
		meta: {
			layout: POWER_LAYOUT,
		},
		async beforeEnter(to, from, next) {
			store.commit("contribution/SET_CLINICAL_CASE", null, {
				root: true,
			});
			store.commit("contribution/SET_QUESTIONS", [], { root: true });
			const guard =
				store.getters["auth/isAuthenticated"] &&
				(store.getters["auth/isAdmin"] ||
					store.getters["auth/isModerator"] ||
					store.getters["auth/isEditor"]);
			if (guard) {
				return next();
			}
			next({ name: "/" });
		},
	},
	{
		path: "/x/reports",
		name: "reports",
		component: ReportsPage,
		meta: {
			layout: POWER_LAYOUT,
		},
		async beforeEnter(to, from, next) {
			const guard =
				store.getters["auth/isAuthenticated"] &&
				(store.getters["auth/isAdmin"] ||
					store.getters["auth/isModerator"]);
			if (guard) {
				return next();
			}
			next({ name: "/" });
		},
	},
	{
		path: "/x/users",
		name: "users",
		component: UsersPage,
		meta: {
			layout: POWER_LAYOUT,
		},
		async beforeEnter(to, from, next) {
			const guard =
				store.getters["auth/isAuthenticated"] &&
				(store.getters["auth/isAdmin"] ||
					store.getters["auth/isModerator"]);
			if (guard) {
				return next();
			}
			next({ name: "/" });
		},
	},
];

const router = new VueRouter({
	mode: "history",
	// eslint-disable-next-line no-undef
	base: process.env.BASE_URL,

	routes: [
		...defaultLayoutRoutes,
		...clientLayoutRoutes,
		...focusLayoutRoutes,
		...powerLayoutRoutes,
		{
			path: "/:pathMatch(.*)*",
			name: "notFound",
			component: NotFoundPage,
			meta: {
				layout: DEFAULT_LAYOUT,
			},
		},
	],

	scrollBehavior(to, from, savedPosition) {
		window.scrollTo(0, 0);
	},
});

router.beforeEach(async (to, from, next) => {
	await store.dispatch("auth/authenticated", null, { root: true });
	next();
});

export default router;
