import { getRequest } from "@/helpers/index.js";
import getUrl from "@/constants/api/index.js";

const state = {
	courses: [],
	coursesPageInfo: null,
};

const mutations = {
	SET_COURSES(state, courses) {
		state.courses = courses;
	},
	SET_COURSES_PAGE_INFO(state, pageInfo) {
		state.coursesPageInfo = pageInfo;
	},
};

const actions = {
	async getCourses({ state }, { moduleId, orderBy }) {
		const params = new URLSearchParams();
		params.append("moduleId", moduleId);
		params.append("orderBy", orderBy);
		if (state.coursesPageInfo?.nextPage) {
			params.append("cursor", state.coursesPageInfo.nextPage);
		}

		const url = getUrl("prioritizer") + "?" + params.toString();

		const { data, error, message } = await getRequest(url, {
			withCredentials: true,
		});

		if (error) {
			return { error, message };
		}
		if (!state.coursesPageInfo) {
			state.courses = data.edges;
			state.coursesPageInfo = data.pageInfo;
		} else {
			for (let edge of data.edges) {
				if (!state.courses.find((course) => course.id === edge.id)) {
					state.courses.push(edge);
				}
			}
			state.coursesPageInfo = data.pageInfo;
		}
	},
	async getSources({ state }, { courseId, orderBy }) {
		const params = new URLSearchParams();
		params.append("orderBy", orderBy);

		const course = state.courses.find((course) => course.id === courseId);
		if (course?.sourcesPageInfo?.nextPage) {
			params.append("cursor", course.sourcesPageInfo.nextPage);
		}

		const url = `${getUrl("prioritizer")}/${courseId}?${params.toString()}`;

		const { data, error, message } = await getRequest(url, {
			withCredentials: true,
		});

		if (error) {
			return { error, message };
		}
		state.courses = state.courses.map((course) => {
			if (course.id === courseId) {
				if (!course.sourcesPageInfo) {
					course.sources = data.edges;
					course.sourcesPageInfo = data.pageInfo;
				} else {
					for (let edge of data.edges) {
						if (
							!course.sources.find(
								(source) => source.id === edge.id
							)
						) {
							course.sources.push(edge);
						}
					}
					course.sourcesPageInfo = data.pageInfo;
				}
			}
			return course;
		});
	},
};

const getters = {};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
};
