<template>
	<v-snackbar v-model="snackbar" :color="snackbarColor" outlined style="z-index: 500">
		<template v-slot:default>
			<div class="d-flex align-center">
				<v-icon left :color="snackbarColor">{{ snackbarIcon }}</v-icon>
				<span class="font-weight-bold">
					{{ capitalizeFirst($t(snackbarMessage)) }}
				</span>
			</div>
		</template>
	</v-snackbar>
</template>

<script>
export default {
	name: "PrimarySnackbar",
	data() {
		return {
			snackbar: false,
			snackbarMessage: "",
			snackbarColor: "",
			snackbarIcon: null,
		};
	},
	methods: {
		hide() {
			this.snackbar = false;
		},
		showErrorSnackbar(message) {
			this.hide();
			this.snackbarColor = "error";
			this.snackbarMessage = message;
			this.snackbarIcon = "mdi-alert-circle";
			this.snackbar = true;
		},
		showSuccessSnackbar(message) {
			this.hide();
			this.snackbarColor = "success";
			this.snackbarMessage = message;
			this.snackbarIcon = "mdi-check-decagram";
			this.snackbar = true;
		},
	},
};
</script>

<style scoped></style>
