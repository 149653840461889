import { patchRequest, postRequest, getRequest } from "@/helpers/index.js";
import getUrl from "@/constants/api/index.js";

const state = {
	pointOfSales: [],
};

const mutations = {
	SET_POINT_OF_SALES: function (state, pointOfSales) {
		state.pointOfSales = pointOfSales;
	},
	ADD_POINT_OF_SALE: function (state, pointOfSale) {
		state.pointOfSales.push(pointOfSale);
	},
	EDIT_POINT_OF_SALE: function (state, { index, pointOfSale }) {
		state.pointOfSales.splice(index, 1, pointOfSale);
	},
	DELETE_POINT_OF_SALE: function (state, index) {
		state.pointOfSales.splice(index, 1);
	},
	RESET_POINT_OF_SALES_STATE: function (state) {
		state.pointOfSales = [];
	},
};

const actions = {
	async getPointOfSales(context) {
		const { data, error } = await getRequest(getUrl("pointOfSales"), {
			withCredentials: true,
		});
		if (!error) {
			context.commit("SET_POINT_OF_SALES", data);
		}
	},
	async addPointOfSale(context, input) {
		const { data, error, message } = await postRequest(
			`${getUrl("pointOfSales")}/point-of-sale`,
			input,
			{
				withCredentials: true,
			}
		);
		if (!error) {
			context.commit("ADD_POINT_OF_SALE", data);
			return { error: false };
		}
		return { error: true, message: message };
	},
	async editPointOfSale(context, { id, input }) {
		const { data, error, message } = await patchRequest(
			`${getUrl("pointOfSales")}/point-of-sale/${id}`,
			input,
			{
				withCredentials: true,
			}
		);
		if (!error) {
			context.commit("EDIT_POINT_OF_SALE", {
				index: context.state.pointOfSales.findIndex(
					(item) => item.id === id
				),
				pointOfSale: data,
			});
			return { error: false };
		}
		return { error: true, message: message };
	},
};

const getters = {
	pointOfSales: (state) => state.pointOfSales,
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
};
