import axios from "axios";

// PREFORM HTTP GET REQUEST
export async function getRequest(path, config) {
	try {
		const response = await axios.get(path, config);
		return {
			response: response,
			error: false,
			data: response.data,
		};
	} catch (error) {
		return {
			error: true,
			message: error.response?.data?.message,
		};
	}
}

// PREFORM HTTP POST REQUEST
export async function postRequest(path, data, config) {
	try {
		const response = await axios.post(path, data, config);
		return {
			response: response,
			error: false,
			data: response.data,
		};
	} catch (error) {
		return {
			error: true,
			message: error.response?.data?.message,
		};
	}
}

// PREFORM HTTP PUT REQUEST
export async function putRequest(path, data, config) {
	try {
		const response = await axios.put(path, data, config);
		return {
			error: false,
			data: response.data,
		};
	} catch (error) {
		return {
			error: true,
			message: error.response?.data?.message,
		};
	}
}

// PREFORM HTTP PATCH REQUEST
export async function patchRequest(path, data, config) {
	try {
		const response = await axios.patch(path, data, config);
		return {
			error: false,
			data: response.data,
		};
	} catch (error) {
		return {
			error: true,
			message: error.response?.data?.message,
		};
	}
}

// PREFORM HTTP DELETE REQUEST
export async function deleteRequest(path, config) {
	try {
		const response = await axios.delete(path, config);
		return {
			error: false,
			data: response.data,
		};
	} catch (error) {
		return {
			error: true,
			message: error.response?.data?.message,
		};
	}
}
