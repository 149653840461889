import { deleteRequest, patchRequest, postRequest } from "@/helpers/index.js";
import getUrl from "@/constants/api/index.js";

const state = {
	clinicalCases: [],
};

const mutations = {
	SET_CLINICAL_CASES: function (state, clinicalCases) {
		state.clinicalCases = clinicalCases;
	},
	ADD_CLINICAL_CASE: function (state, clinicalCase) {
		state.clinicalCases.push(clinicalCase);
	},
	EDIT_CLINICAL_CASE: function (state, { index, clinicalCase }) {
		state.clinicalCases.splice(index, 1, clinicalCase);
	},
	DELETE_CLINICAL_CASE: function (state, index) {
		state.clinicalCases.splice(index, 1);
	},
	RESET_CLINICAL_CASES_STATE: function (state) {
		state.clinicalCases = [];
	},
};

const actions = {
	async getClinicalCases(context, body) {
		const { data, error, message } = await postRequest(
			getUrl("clinicalCases"),
			body,
			{
				withCredentials: true,
			}
		);
		if (!error) {
			context.commit("SET_CLINICAL_CASES", data);
			return { error: false };
		}
		return { error: error, message: message };
	},
	async addClinicalCase(context, input) {
		const { data, error, message } = await postRequest(
			`${getUrl("clinicalCases")}/clinical-case`,
			input,
			{
				withCredentials: true,
			}
		);
		if (!error) {
			context.commit("ADD_CLINICAL_CASE", data);
			return { error: false };
		}
		return { error: true, message: message };
	},
	async editClinicalCase(context, { id, input }) {
		const { data, error, message } = await patchRequest(
			`${getUrl("clinicalCases")}/clinical-case/${id}`,
			input,
			{
				withCredentials: true,
			}
		);
		if (!error) {
			context.commit("EDIT_CLINICAL_CASE", {
				index: context.state.clinicalCases.findIndex(
					(item) => item.id === id
				),
				clinicalCase: data,
			});
			return { error: false };
		}
		return { error: true, message: message };
	},
	async deleteClinicalCase(context, id) {
		const { error, message } = await deleteRequest(
			`${getUrl("clinicalCases")}/clinical-case/${id}`,
			{ withCredentials: true }
		);
		if (!error) {
			context.commit(
				"DELETE_CLINICAL_CASE",
				context.state.clinicalCases.findIndex((item) => item.id === id)
			);
			return { error: false };
		}
		return { error: true, message: message };
	},
};

const getters = {
	clinicalCases: (state) => state.clinicalCases,
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
};
