import { getRequest, patchRequest, postRequest } from "@/helpers/index.js";
import getUrl from "@/constants/api/index.js";

const state = {
	connectedUser: null,
};

const mutations = {
	SET_CONNECTED_USER: function(state, user) {
		state.connectedUser = user;
	},
	RESET_AUTH_STATE: function(state) {
		state.connectedUser = null;
	},
};

const actions = {
	async login(context, input) {
		const { error, data, message } = await postRequest(
			`${getUrl("auth")}/login`,
			input,
			{
				withCredentials: true,
			},
		);
		if (!error) {
			context.commit("SET_CONNECTED_USER", data.user);
		}
		return { error, message };
	},
	async signup(context, input) {
		const { error, data, message } = await postRequest(
			`${getUrl("auth")}/signup`,
			input,
			{
				withCredentials: true,
			},
		);
		if (!error) {
			context.commit("SET_CONNECTED_USER", data.user);
			return { error: false };
		}
		return { error: true, message: message };
	},
	async logout(context) {
		const { error } = await postRequest(`${getUrl("auth")}/logout`, null, {
			withCredentials: true,
		});
		if (!error) {
			context.commit("SET_CONNECTED_USER", null);
		}
		return !error;
	},
	async authenticated(context) {
		if (context.state.connectedUser) {
			return true;
		}

		context.commit("SET_LOADING_SCREEN", true, { root: true });
		const { data, error } = await getRequest(
			`${getUrl("auth")}/authenticated`,
			{
				withCredentials: true,
			},
		);
		context.commit("SET_LOADING_SCREEN", false, { root: true });
		if (!error) {
			context.commit("SET_CONNECTED_USER", data);
			return true;
		}
		return false;
	},
	async requestValidationCode(context, input) {
		const { error, data, message } = await postRequest(
			`${getUrl("auth")}/request-validation-code`,
			input,
			{
				withCredentials: true,
			},
		);
		if (!error) {
			return { error: false, data: data };
		}
		return { error: true, message: message };
	},
	async validateAccount(context, input) {
		const { error, message } = await patchRequest(
			`${getUrl("auth")}/validate-account`,
			input,
			{
				withCredentials: true,
			},
		);
		if (!error) {
			const user = context.state.connectedUser;
			user.status = "valid";
			context.commit("SET_CONNECTED_USER", user);
			return { error: false };
		}
		return { error: true, message: message };
	},
	async requestResetPasswordCode(context, input) {
		const { error, data, message } = await postRequest(
			`${getUrl("auth")}/request-reset-code`,
			input,
			{
				withCredentials: true,
			},
		);
		if (!error) {
			return { error: false, data: data };
		}
		return { error: true, message: message };
	},
	async resetPassword(context, input) {
		const { error, message } = await patchRequest(
			`${getUrl("auth")}/reset-password`,
			input,
			{
				withCredentials: true,
			},
		);
		if (!error) {
			return { error: false };
		}
		return { error: true, message: message };
	},
	async editProfile(context, input) {
		const { error, data, message } = await patchRequest(
			`${getUrl("users")}/user/profile`,
			input,
			{
				withCredentials: true,
			},
		);
		if (!error) {
			context.commit("SET_CONNECTED_USER", data);
			return { error: false };
		}
		return { error: true, message: message };
	},
	async editEmail(context, input) {
		const { error, message } = await patchRequest(
			`${getUrl("users")}/user/profile/email`,
			input,
			{
				withCredentials: true,
			},
		);
		if (!error) {
			return { error: false };
		}
		return { error: true, message: message };
	},
	async editPassword(context, input) {
		const { error, message } = await patchRequest(
			`${getUrl("users")}/user/profile/update-password`,
			input,
			{
				withCredentials: true,
			},
		);
		if (!error) {
			return { error: false };
		}
		return { error: true, message: message };
	},
	async verifyToken(context) {
		const url = `${getUrl("auth")}/verify-token`;
		const { error, data, message } = await getRequest(url, {
			withCredentials: true,
		});
		if (!error) {
			return { error: false, data: data };
		}
		return { error: true, message: message };
	},
};

const getters = {
	connectedUser: (state) => state.connectedUser,
	isAuthenticated: (state) => !!state.connectedUser,
	isAdmin: (state) => {
		if (!state.connectedUser) {
			return false;
		}
		return state.connectedUser.role.label === "admin";
	},
	isModerator: (state) => {
		if (!state.connectedUser) {
			return false;
		}
		return state.connectedUser.role.label === "moderator";
	},
	isEditor: (state) => {
		if (!state.connectedUser) {
			return false;
		}
		return state.connectedUser.role.label === "editor";
	},
	isPremium: (state) => {
		if (!state.connectedUser) {
			return false;
		}
		return state.connectedUser.isPremium;
	},
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
};
