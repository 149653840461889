import { postRequest } from "../../helpers";
import getUrl from "../../constants/api/index.js";

const state = {
	themes: [],
};

const mutations = {
	SET_THEMES: function (state, themes) {
		state.themes = themes;
	},
	ADD_THEME: function (state, theme) {
		state.themes.push(theme);
	},
	EDIT_THEME: function (state, { index, theme }) {
		state.themes.splice(index, 1, theme);
	},
	DELETE_THEME: function (state, index) {
		state.themes.splice(index, 1);
	},
	RESET_THEMES_STATE: function (state) {
		state.themes = [];
	},
};

const actions = {
	async getThemes(context, body) {
		const { data, error, message } = await postRequest(
			getUrl("themes"),
			body,
			{
				withCredentials: true,
			}
		);
		if (!error) {
			context.commit("SET_THEMES", data);
		}
		return { data, error, message };
	},
	editTheme: function (context, theme) {
		context.commit("EDIT_THEME", {
			index: context.state.themes.findIndex(
				(item) => item.id === theme.id
			),
			theme: theme,
		});
	},
	deleteTheme: function (context, theme) {
		context.commit(
			"DELETE_THEME",
			context.state.themes.findIndex((item) => item.id === theme.id)
		);
	},
};

const getters = {
	themes: (state) => state.themes,
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
};
