export function getAPIUrl(path, version) {
	// eslint-disable-next-line no-undef
	const BASE_URL = process.env.VUE_APP_ENV === "development"
		? "http://localhost:60070/api"
		: "https://medlibro.co/api";

	return `${BASE_URL}/v${version}/${path}`;
}

export const LETTERS = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";

// Layouts
export const FOCUS_LAYOUT = "focus-layout";

// ERROR CODES ~ 8 DIGITS
export const NON_PREMIUM_USER_ERROR_CODE = "90OWL6D7";
export const LIMITED_LICENSE_ERROR_CODE = "8UIW7MN2";
