<template>
	<v-app>
		<v-container
			v-if="loadingScreen"
			style="height: 100%; display: grid; place-items: center"
		>
			<div
				class="d-flex flex-column"
				style="gap: 12px; place-items: center"
			>
				<img
					v-if="$vuetify.theme.dark"
					src="@/assets/logo/opso-dark.png"
					style="width: 48px; height: 48px"
					alt="OPSO Logo"
				/>
				<img
					v-else
					src="@/assets/logo/opso-light.png"
					style="width: 48px; height: 48px"
					alt="OPSO Logo"
				/>

				<v-progress-circular indeterminate size="24" />
			</div>
		</v-container>
		<div v-else>
			<client-layout v-if="$route.meta.layout === CLIENT_LAYOUT" />
			<power-layout v-else-if="$route.meta.layout === POWER_LAYOUT" />
			<default-layout v-else-if="$route.meta.layout === DEFAULT_LAYOUT" />
			<focus-layout v-else-if="$route.meta.layout === FOCUS_LAYOUT" />
		</div>

		<!-- Global Components -->
		<primary-snackbar ref="primarySnackbar" />
	</v-app>
</template>

<script>
const FocusLayout = () => import("@/next/layouts/FocusLayout.vue");
const ClientLayout = () => import("@/views/layouts/ClientLayout.vue");
const PowerLayout = () => import("@/views/layouts/PowerLayout.vue");
const DefaultLayout = () => import("@/views/layouts/DefaultLayout.vue");

import PrimarySnackbar from "@/views/components/PrimarySnackbar.vue";

import {
	CLIENT_LAYOUT,
	POWER_LAYOUT,
	DEFAULT_LAYOUT,
} from "@/constants/index.js";

import { FOCUS_LAYOUT } from "@/next/constants";

import { mapState } from "vuex";

export default {
	name: "App",
	components: {
		FocusLayout,
		ClientLayout,
		PowerLayout,
		DefaultLayout,
		PrimarySnackbar,
	},
	beforeCreate() {
		this.$vuetify.theme.dark = localStorage.getItem("theme") === "dark";
	},
	mounted() {
		this.$root.primarySnackbar = this.$refs.primarySnackbar;
	},
	data() {
		return {
			CLIENT_LAYOUT,
			POWER_LAYOUT,
			DEFAULT_LAYOUT,
			FOCUS_LAYOUT,
		};
	},
	computed: {
		...mapState(["loadingScreen"]),
	},
};
</script>
