import { postRequest } from "@/helpers";
import getUrl from "@/constants/api";

const state = {
	locations: [],
};

const mutations = {
	SET_LOCATIONS: function (state, locations) {
		state.locations = locations;
	},
	ADD_LOCATION: function (state, location) {
		state.locations.push(location);
	},
	EDIT_LOCATION: function (state, { index, location }) {
		state.locations.splice(index, 1, location);
	},
	DELETE_LOCATION: function (state, index) {
		state.locations.splice(index, 1);
	},
	RESET_LOCATIONS_STATE: function (state) {
		state.locations = [];
	},
};

const actions = {
	async getLocations(context, body) {
		const { data, error } = await postRequest(getUrl("locations"), body, {
			withCredentials: true,
		});
		if (!error) {
			context.commit("SET_LOCATIONS", data);
		}
	},
	addLocation: function (context, location) {},
	editLocation: function (context, location) {
		context.commit("EDIT_LOCATION", {
			index: context.state.locations.findIndex(
				(item) => item.id === location.id
			),
			location: location,
		});
	},
	deleteLocation: function (context, location) {
		context.commit(
			"DELETE_LOCATION",
			context.state.locations.findIndex((item) => item.id === location.id)
		);
	},
};

const getters = {
	locations: (state) => state.locations,
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
};
