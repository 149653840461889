import { postRequest } from "../../helpers";
import getUrl from "../../constants/api/index.js";

const state = {
	courses: [],
};

const mutations = {
	SET_COURSES: function (state, courses) {
		state.courses = courses;
	},
	ADD_COURSE: function (state, course) {
		state.courses.push(course);
	},
	EDIT_COURSE: function (state, { index, course }) {
		state.courses.splice(index, 1, course);
	},
	DELETE_COURSE: function (state, index) {
		state.courses.splice(index, 1);
	},
	RESET_COURSES_STATE: function (state) {
		state.courses = [];
	},
};

const actions = {
	getCourses: async function (context, body) {
		const { data, error } = await postRequest(getUrl("courses"), body, {
			withCredentials: true,
		});
		if (!error) {
			context.commit("SET_COURSES", data);
		}
	},
	addCourse: function (context, course) {},
	editCourse: function (context, course) {
		context.commit("EDIT_COURSE", {
			index: context.state.courses.findIndex(
				(item) => item.id === course.id
			),
			course: course,
		});
	},
	deleteCourse: function (context, course) {
		context.commit(
			"DELETE_COURSE",
			context.state.courses.findIndex((item) => item.id === course.id)
		);
	},
};

const getters = {
	courses: (state) => state.courses,
	coursesByThemeByChapters: (state) => {
		return (themeId, chapterIds) => {
			if (themeId && chapterIds) {
				return state.courses.filter((course) => {
					return (
						course.themeId === themeId &&
						course.chapters.find((item) =>
							chapterIds.includes(item.id)
						)
					);
				});
			}
		};
	},
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
};
